import React, { useContext } from "react";
import MultiSelect from "@components/multiSelect/multiSelect";
import PhotoUpload from "@components/PhotoUpload/PhotoUpload";
import Group from "@components/group/GroupComponent";
import Radio from "@components/radio/RadioComponent";
import CheckBox from "@components/checkBox/CheckBoxComponent";
import Select from "@components/select/SelectComponent";
import AnswerYesNo from "@components/answerYesNo/AnswerYesNoComponent";
import Phone from "@components/surveyQuestions/questions/phone/PhoneComponent";
import Text from "@components/surveyQuestions/questions/text/TextComponent";
import NumberQuestionComponent from "@components/surveyQuestions/questions/number/NumberQuestionComponent";
import DatePicker from "@components/surveyQuestions/questions/datePicker/DatePickerComponent";
import AutocompleteAddress from "@components/surveyQuestions/questions/address/AddressQuestionComponent";
import Note from "@components/surveyQuestions/questions/note/NoteComponent";
import AppService from "../../service/AppService/AppService";
import { unansweredQuestions } from "../../shared/AnswersTracker/AnswersTracker";
import DynamicGroup from "../DynamicGroup/DynamicGroup";
import UploadComponent from "../surveyQuestions/questions/upload/UploadComponent";
import { AuthContext } from "../../AuthContext";
import { surveyQuestionMethods } from "../../shared/Questions/Questions";

function Question({
    question,
    questions,
    selectedTopic,
    onAnswer,
    updateProgress,
    showUnansweredWarning,
    isSurveyReadOnly,
    isDynamicGroup,
    emailIsValid,
    isLiteSurvey,
    className
}) {
    const authContext = useContext(AuthContext);
    const showWarning =
        showUnansweredWarning && unansweredQuestions.containsQuestion(selectedTopic.sequenceId || selectedTopic.id, question.sequenceId);

    const determineUnansweredBorderClass = () => {
        if (AppService.accountType === "surrogate") {
            if (question.type === "PHONE" && showWarning) {
                return "unanswered-border-surrogate";
            }
            if (question.salesForceField?.fieldName === "Email" && (showWarning || !emailIsValid)) {
                return "unanswered-border-surrogate";
            }
        }
        return showWarning ? `unanswered-border-${AppService.accountType}` : "";
    };

    const unansweredBorderClass = !authContext.isSurveyFinished() && determineUnansweredBorderClass();
    if (!question.originalText) {
        question.originalText = question.text;
    }

    let modifiedQuestionText = question.originalText;

    if (question?.jsonRelation && !question.jsonRelation.source) {
        const questionWithRelatedAnswers = questions.find((q) => q.sequenceId === question.jsonRelation.relatedQuestionSequence);
        const relatedQuestion = questionWithRelatedAnswers?.answers
            .filter((a) => !questionWithRelatedAnswers[surveyQuestionMethods.lastAnswerListName()]?.includes(a.text))
            .map((a) => a.text);
        let relatedItemsList;
        if (relatedQuestion) {
            relatedItemsList = relatedQuestion.join(", ");
        } else {
            relatedItemsList = question?.jsonRelation?.defaultItems;
        }
        modifiedQuestionText = `${modifiedQuestionText} ${relatedItemsList}`;
    }
    question.text = modifiedQuestionText;
    switch (question.type) {
        case "YES_NO":
            return (
                <AnswerYesNo
                    className={unansweredBorderClass}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "PHONE":
            return (
                <Phone
                    className={`${className} ${unansweredBorderClass}`}
                    updateProgress={updateProgress}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "SINGLE_ONE":
            return (
                <Radio
                    className={unansweredBorderClass}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "MULTI_SELECT":
        case "SELECT_MANY":
            return (
                <CheckBox
                    className={unansweredBorderClass}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "TEXT":
            return (
                <Text
                    className={`${className} ${unansweredBorderClass}`}
                    updateProgress={updateProgress}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                    isLiteSurvey={isLiteSurvey}
                />
            );
        case "NUMBER":
            return (
                <NumberQuestionComponent
                    className={unansweredBorderClass}
                    updateProgress={updateProgress}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "DATE":
            return (
                <DatePicker
                    className={unansweredBorderClass}
                    updateProgress={updateProgress}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "ADDRESS":
            return (
                <AutocompleteAddress
                    className={unansweredBorderClass}
                    onAnswer={onAnswer}
                    question={question}
                    key={question.sequenceId}
                    readonly={isSurveyReadOnly}
                    selectedTopic={selectedTopic}
                    survey={AppService.surveyId}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "DROPDOWN":
            return (
                <Select
                    className={unansweredBorderClass}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "DROPDOWN_MULTISELECT":
            return (
                <MultiSelect
                    className={unansweredBorderClass}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "UPLOAD":
            if (AppService.accountType === "surrogate") {
                return (
                    <UploadComponent
                        className={unansweredBorderClass}
                        readonly={isSurveyReadOnly}
                        onAnswer={onAnswer}
                        key={question.sequenceId}
                        selectedTopic={selectedTopic}
                        question={question}
                    />
                );
            } else {
                return (
                    <PhotoUpload
                        className={unansweredBorderClass}
                        readonly={isSurveyReadOnly}
                        onAnswer={onAnswer}
                        key={question.sequenceId}
                        selectedTopic={selectedTopic}
                        question={question}
                        multiple={false}
                        isDynamicGroup={isDynamicGroup}
                    />
                );
            }
        case "MULTI_UPLOAD":
            return (
                <PhotoUpload
                    className={unansweredBorderClass}
                    readonly={isSurveyReadOnly}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    multiple={true}
                    isDynamicGroup={isDynamicGroup}
                />
            );
        case "NOTE":
            return <Note key={question.sequenceId} question={question} />;
        case "GROUP":
            return (
                <Group
                    showUnansweredWarning={showUnansweredWarning}
                    updateProgress={updateProgress}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                />
            );
        case "DYNAMIC_GROUP":
            return (
                <DynamicGroup
                    className={unansweredBorderClass}
                    updateProgress={updateProgress}
                    onAnswer={onAnswer}
                    key={question.sequenceId}
                    selectedTopic={selectedTopic}
                    question={question}
                    readonly={isSurveyReadOnly}
                />
            );
    }
    return null;
}

export default Question;
