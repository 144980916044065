import { useEffect, useState } from "react";
import MultiSelectDropDownComponent from "../../../../../components/multiSelectDropDown/MultiSelectDropDownComponent";
import "./QuestionnaireComponent.scss";
import CalendarComponent from "../../../../../components/calendar/CalendarComponent";
import { cloneDeep } from "lodash";
import classNames from "classnames";
import removeIcon from "../../../../../resources/images/delete_basket.svg";

function QuestionnaireComponent({ cyclesInfo, setCyclesInfo, setIsCyclesInfoUpdated }) {
    const defaultInstance = {
        date: "",
        retrieved: "",
        fert: "",
        cryo: "",
        is_conab: "",
        preg_outcome: "",
        cryo_outcome: "",
        notes: "",
        cycle_notes: ""
    };
    const [questionBlocks, setQuestionBlocks] = useState([defaultInstance]);

    useEffect(() => {
        if (cyclesInfo.userValue) {
            setQuestionBlocks(cyclesInfo.userValue);
        }
    }, [cyclesInfo.userValue]);

    const addQuestionBlock = () => {
        setQuestionBlocks([...questionBlocks, cloneDeep(defaultInstance)]);
    };

    const removeBlock = (index) => {
        const updatedBlocks = questionBlocks.filter((_, i) => i !== index);
        setQuestionBlocks(updatedBlocks);
        setCyclesInfo({ ...cyclesInfo, userValue: updatedBlocks });
        setIsCyclesInfoUpdated(true);
    };

    const handleUpdate = (index, key, value) => {
        const updatedBlocks = [...questionBlocks];
        updatedBlocks[index][key] = value;
        setQuestionBlocks(updatedBlocks);
        setCyclesInfo({ ...cyclesInfo, userValue: questionBlocks });
        setIsCyclesInfoUpdated(true);
    };
    if (!cyclesInfo) return null;

    return (
        <div className="questionnaire-container">
            {questionBlocks.map((block, index) => (
                <QuestionBlock key={index} index={index} handleUpdate={handleUpdate} block={block} removeBlock={removeBlock} />
            ))}
            <div onClick={addQuestionBlock} className="add-instance">
                Add
            </div>
        </div>
    );
}

export default QuestionnaireComponent;

function QuestionBlock({ index, handleUpdate, block, removeBlock }) {
    const pregnancyOutcomeItems = [
        { text: "Chemical Pregnancy" },
        { text: "No Pregnancy" },
        { text: "Miscarriage" },
        { text: "Pregnancy" }
    ];
    return (
        <div className="question-block">
            <div className="item-field edit-box">
                <p>Retrieval Date (Block {index + 1}):</p>
                <CalendarComponent
                    answer={{ userAnswer: block.date || "", value: block.date || "" }}
                    onChange={(value) => handleUpdate(index, "date", value)}
                />
                {index > 0 && <img src={removeIcon} alt="remove" className="remove" onClick={() => removeBlock(index)} />}
            </div>
            <div className="fields-row">
                <div className="item-field edit-box">
                    <p>Eggs Retrieved</p>
                    <input
                        value={block.retrieved}
                        data-testid="retrieved-input"
                        type="text"
                        onChange={(e) => handleUpdate(index, "retrieved", e.target.value)}
                        className="box-item"
                    />
                </div>

                <div className="item-field edit-box">
                    <p>Eggs Fertilized</p>
                    <input
                        className="box-item"
                        value={block.fert}
                        type="text"
                        onChange={(e) => handleUpdate(index, "fert", e.target.value)}
                    />
                </div>

                <div className="item-field edit-box">
                    <p>Cryo-Preserved</p>
                    <input
                        className="box-item"
                        value={block.cryo}
                        type="text"
                        onChange={(e) => handleUpdate(index, "cryo", e.target.value)}
                    />
                </div>
            </div>
            <div className="fields-row">
                <div className="item-field edit-box">
                    <p>Pregnancy Outcome</p>
                    <MultiSelectDropDownComponent
                        placeholder={""}
                        selected={block.preg_outcome?.text ? [block.preg_outcome?.text] : null}
                        showArrow={true}
                        items={[...pregnancyOutcomeItems, { text: "Freeze All" }]}
                        selectionLimit={1}
                        onChange={(items) => handleUpdate(index, "preg_outcome", items[0])}
                    />
                </div>
                <div className="item-field edit-box">
                    <p>Cryo Cycle Outcome</p>
                    <MultiSelectDropDownComponent
                        placeholder={""}
                        selected={block.cryo_outcome?.text ? [block.cryo_outcome?.text] : null}
                        showArrow={true}
                        items={pregnancyOutcomeItems}
                        selectionLimit={1}
                        onChange={(items) => handleUpdate(index, "cryo_outcome", items[0])}
                    />
                </div>

                <div className="item-field edit-box">
                    <p>ConceiveAbilities Cycle</p>
                    <button
                        className={classNames("yes_no", { selected: block.is_conab === "Yes" })}
                        onClick={() => handleUpdate(index, "is_conab", "Yes")}
                    >
                        Yes
                    </button>
                    <button
                        className={classNames("yes_no", { selected: block.is_conab === "No" })}
                        onClick={() => handleUpdate(index, "is_conab", "No")}
                    >
                        No
                    </button>
                </div>
            </div>

            <div className="item-field edit-box">
                <p>Internal Notes</p>
                <textarea data-testid="notes-input" value={block.notes} onChange={(e) => handleUpdate(index, "notes", e.target.value)} />
            </div>

            <div className="item-field edit-box">
                <p>Cycle Notes</p>
                <textarea value={block.cycle_notes} onChange={(e) => handleUpdate(index, "cycle_notes", e.target.value)} />
            </div>
        </div>
    );
}
