import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import "./LoginComponent.scss";

import PasswordTextBox from "@components/passwordTextBox/PasswordTextBoxComponent";
import AccountSecurityLayout from "@components/accountSecurityLayout/AccountSecurityLayoutComponent";
import Constants from "@helper/Constants";
import i18n from "i18next";
import AppService from "@service/AppService/AppService";
import { AuthContext } from "../../AuthContext";
import { UserSurveyStatus } from "../../helper/Constants";

const LoginComponent = () => {
    const { login, isSurveyFinished } = useContext(AuthContext);
    const history = useHistory();

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [redirectToProfile, setRedirectToProfile] = useState(false);
    const [redirectToSurvey, setRedirectToSurvey] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);
    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        AppService.changeLanguageHandler("en", history);
    }, [history]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === "email") setEmail(value);
    };

    const handlePasswordChange = (password) => {
        setPassword(password);
    };

    const isEmailValid = () => {
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            email
        );
    };

    const isPasswordValid = () => {
        return password.length > 0;
    };

    const validateForm = () => {
        return isEmailValid() && isPasswordValid();
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [email, password]);

    const doLogin = () => {
        if (validateForm()) {
            setShowProgress(true);
            login(email, password)
                .then((value) => {
                    if (value) {
                        if (isSurveyFinished()) {
                            setRedirectToProfile(true);
                        } else {
                            setRedirectToSurvey(true);
                        }
                    }
                    setShowProgress(false);
                })
                .catch(() => {
                    setShowLoginError(true);
                    setShowProgress(false);
                });
        } else {
            setShowLoginError(true);
            setShowProgress(false);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        doLogin();
    };

    const isEmailFieldValid = email.length > 0 && !isEmailValid();
    const emailFieldClassName = isEmailFieldValid ? "field-error" : "";

    if (redirectToProfile) {
        switch (AppService.userSurveyStatus) {
            case UserSurveyStatus.COMPLETED:
            case UserSurveyStatus.ACTIVE_LIVE:
            case UserSurveyStatus.MATCHED:
                return <Redirect to="/accepted" push={true} />;
            case UserSurveyStatus.REJECTED:
            case UserSurveyStatus.INITIAL_REJECTED:
                return <Redirect to="/home" push={true} />;
            default:
                return <Redirect to={"/start"} push={true} />;
        }
    }

    if (redirectToSurvey) {
        return <Redirect to={"/survey"} push={true} />;
    }

    const path = history.location.pathname;
    const loginPath = "/login";

    return (
        <AccountSecurityLayout>
            {showProgress ? <div className="animated-gradient" /> : null}
            <h1>{i18n.t("Welcome back!")}</h1>
            <p className="security-form-description" />
            <form onSubmit={handleFormSubmit} className="form">
                <div className="form-item">
                    <label htmlFor="lblEmail">{i18n.t("Email")}</label>
                    <input type="text" id="lblEmail" name="email" className={emailFieldClassName} value={email} onChange={handleChange} />
                    {isEmailFieldValid && <label className="form-error">Email address not found</label>}
                </div>

                <PasswordTextBox title={i18n.t("Password")} id="lblPassword" onPasswordChange={handlePasswordChange} />

                {showLoginError && (
                    <div className="login-error">
                        {i18n.t("login_warning_text")} <a href="/app/reset">{i18n.t("login_warning_link")}</a>?
                    </div>
                )}

                <button type="submit" className={`button login-button ${isFormValid ? "" : "disabled"}`}>
                    {i18n.t("Login")}
                </button>

                <div className="forgot-password">
                    <a className="link" href="/app/reset">
                        {i18n.t("Forgot your password?")}
                    </a>
                </div>
                <div className="registration-help">
                    <p>{i18n.t("Do you need a new account ?")}</p>
                </div>
                <div className="login-links">
                    <a className="link" href={path === loginPath ? Constants.SIGN_UP_URLS.SURROGATE : "/app/surrogates/register"}>
                        {i18n.t("Surrogate")}
                    </a>
                    <a className="link" href={path === loginPath ? Constants.SIGN_UP_URLS.PARENT : "/app/parents/register"}>
                        {i18n.t("Parent")}
                    </a>
                    <a className="link" href={path === loginPath ? Constants.SIGN_UP_URLS.EGG_DONOR : "/app/egg-donors/register"}>
                        Egg Donor
                    </a>
                </div>
            </form>
        </AccountSecurityLayout>
    );
};

export default LoginComponent;
