import React, { useState } from "react";

import "./AccountSecurityLayouComponent.scss";
import { withRouter } from "react-router-dom";
import logo from "@resources/images/conceiveAbilities-logo.svg";
import rightBg from "@resources/images/security-bg.jpg";
import Constants from "@helper/Constants";
import Footer from "../Footer/Footer";

function AccountSecurityLayoutComponent({ children }) {
    const [redirectToPortal, setRedirectToPortal] = useState(false);

    if (redirectToPortal) {
        window.location.href = Constants.PORTAL_URL;
    }

    return (
        <div className="row security">
            <div className="column left-column">
                <header>
                    <img src={logo} className="app-logo" alt="ConceiveAbilities" onClick={() => setRedirectToPortal(true)} />
                    {/*<LanguagePicker history={this.props.history} />*/}
                    {/* <img src={menu} className="app-menu" alt="Menu" /> */}
                </header>
                <div className="security-form">{children}</div>
                <Footer halfWidth isVisible />
            </div>
            <div className="column right-column" tabIndex="0">
                <img src={rightBg} alt="" className="security-right-image" />
            </div>
        </div>
    );
}

export default withRouter(AccountSecurityLayoutComponent);
