import React from "react";
import Question from "../../Question/Question";
import { unansweredQuestions } from "../../../shared/AnswersTracker/AnswersTracker";

function LiteQuestionElements({ question, selectedSection, setSelectedSection, answers, setAnswers, showUnansweredWarning, emailIsValid }) {
    const setLastAnswerId = (question, answer) => {
        if (question.type === "NUMBER") {
            if (answer.userAnswer && answer.userAnswer.length > 0) {
                question.lastAnswerIds = [answer.id];
            } else {
                question.lastAnswerIds = [];
            }
        } else if (question.type !== "SELECT_MANY" && question.type !== "MULTI_SELECT") {
            question.lastAnswerIds = [answer.id];
        }

        setSelectedSection((prevState) => ({
            ...prevState,
            questions: prevState.questions.map((q) => (q.sequenceId === question.sequenceId ? question : q))
        }));
    };

    const onAnswer = (topic, question, answer) => {
        const values = [];
        setLastAnswerId(question, answer);

        for (let a of question.answers) {
            let selectedValue;
            if (question.type === "SELECT_MANY" || question.type === "MULTI_SELECT") {
                selectedValue = question.lastAnswerIds.includes(a.id);
            } else if (
                question.type === "UPLOAD" ||
                question.type === "TEXT" ||
                question.type === "NUMBER" ||
                question.type === "DATE" ||
                question.type === "ADDRESS" ||
                question.type === "PHONE"
            ) {
                selectedValue = a.userAnswer ?? "";
            } else {
                selectedValue = a.text === answer.text && a.sequenceId === answer.sequenceId;
            }
            if (question.type === "YES_NO") {
                if (answer.id === a.id) {
                    values.push({
                        answerId: a.id,
                        value: answer.text,
                        isDisqualify: answer.isDisqualify
                    });
                }
            } else if (question.type === "SINGLE_ONE" || question.type === "DROPDOWN") {
                if (answer.id === a.id) {
                    values.push({
                        answerId: a.id,
                        value: selectedValue,
                        isDisqualify: answer.isDisqualify
                    });
                }
            } else if (question.type === "ADDRESS") {
                if (answer.id === a.id) {
                    values.push({
                        answerId: a.id,
                        value: a.userAnswer,
                        isDisqualify: answer.isDisqualify
                    });
                }
            } else {
                values.push({
                    answerId: a.id,
                    value: selectedValue,
                    isDisqualify: answer.isDisqualify
                });
            }
        }

        let questionWasAnswered = false;

        answers.forEach((element) => {
            if (element.questionId === question.id) {
                questionWasAnswered = true;
            }
        });
        if (questionWasAnswered) {
            const updatedAnswers = answers.map((element) => {
                if (element.questionId === question.id) {
                    return { ...element, values: values };
                }
                return element;
            });
            setAnswers(updatedAnswers);
        } else {
            setAnswers([...answers, { questionId: question.id, values: values }]);
        }
    };

    const toggleUnansweredWarning = showUnansweredWarning && unansweredQuestions.containsQuestion(selectedSection.id, question.sequenceId);
    const unansweredBorderClass = toggleUnansweredWarning ? "unanswered-border-surrogate" : "";

    return (
        <Question
            className={unansweredBorderClass}
            emailIsValid={emailIsValid}
            onAnswer={onAnswer}
            selectedTopic={selectedSection}
            question={question}
            showUnansweredWarning={showUnansweredWarning}
            isSurveyReadOnly={false}
            isLiteSurvey={true}
        />
    );
}

export default LiteQuestionElements;
