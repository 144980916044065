import React from "react";
import Hint from "@components/hint/HintComponent";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedback } from "@shared/Feedback";
import TextQuestionComponent from "./TextQuestionComponent/TextQuestionComponent";

const TextComponent = ({ question, selectedTopic, onAnswer, className, readonly, showOverlay, isLiteSurvey }) => {
    const feedbackItems = question.answers.map((a) => getFeedback(a)).flat();

    return (
        <div>
            <div className={`survey-question ${className}`}>
                {readonly && <div className="answer-overlay" />}
                {showOverlay && <div className="survey-overlay" />}
                <label className="question-text" htmlFor={getTranslatedTextIfNeeded(question.text)}>
                    {getTranslatedTextIfNeeded(question.text)}
                </label>
                <div className="question-answers">
                    <div className="content">
                        {question.answers.map((a) => (
                            <TextQuestionComponent
                                key={a.sequenceId}
                                answer={a}
                                question={question}
                                onAnswer={onAnswer}
                                selectedTopic={selectedTopic}
                                isLiteSurvey={isLiteSurvey}
                            />
                        ))}
                    </div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
};

export default TextComponent;
