import React from "react";
import "./Footer.scss";
import AppService from "../../service/AppService/AppService";

function Footer({ halfWidth, withBottomPosition, isVisible, withFixedBottomPosition }) {
    let showFooter = isVisible;

    const styles = {
        width: halfWidth ? "100%" : "100vw"
    };

    if (withBottomPosition) {
        styles.position = "absolute";
        styles.bottom = "0";
        styles.right = "0";
        styles.left = "0";
        showFooter = true;
    }

    if (withFixedBottomPosition) {
        styles["background-color"] = "transparent";
        showFooter = true;
        styles.position = "fixed";
        if (AppService.isMobile()) {
            styles.visibility = "hidden";
            styles.left = "0";
        }
    }

    return (
        <footer className={`footer footer-slide-in ${showFooter ? "show" : ""}`} style={styles}>
            <div className="footer-bottom">
                <div className="footer-bottom__left">
                    <a href="https://www.conceiveabilities.com/privacy-policy/" target="_blank" aria-label="Open Privacy Policy">
                        Privacy Policy
                    </a>
                </div>
                <div className="footer-bottom__right">{withFixedBottomPosition ? "" : "© ConceiveAbilities"}</div>
            </div>
        </footer>
    );
}

export default Footer;
