import React, { useContext, useEffect, useState } from "react";
import "./MobileMenuComponent.scss";
import menu from "@resources/images/mobile_menu.svg";
import close from "@resources/images/close.svg";
import menuWhite from "@resources/images/mobile_menu_white.svg";
import closeWhite from "@resources/images/close_white.svg";
import AppService from "@service/AppService/AppService";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { SurveyQuestionsContext } from "../../SurveyQuestionsStore";

const MobileMenuComponent = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const questionContext = useContext(SurveyQuestionsContext);
    const [isMenuOpened, setMenuOpened] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (!event.target.classList.contains("mobile-dropdown-menu")) {
                setMenuOpened(false);
            }
        }
        if (isMenuOpened) {
            document.addEventListener("click", handleClickOutside);
        }
        return () => document.removeEventListener("click", handleClickOutside);
    }, [isMenuOpened]);

    const toggleMenuVisibility = (e) => {
        e.stopPropagation();
        setMenuOpened(!isMenuOpened);
    };

    const doLogout = async () => {
        history.push("/login");
        authContext.logout();
    };

    const redirectTo = () => {
        history.push("/profile");
    };

    let menuIcon = isMenuOpened ? close : menu;

    if (AppService.accountType === "surrogate" || AppService.accountType === "parent") {
        menuIcon = isMenuOpened ? closeWhite : menuWhite;
    }

    const showPrivacyPolicy = ["/donors", "/reserved", "/favorite"].includes(history.location.pathname);

    return (
        <nav className="mobile-menu">
            <img src={menuIcon} className="app-menu" alt="Menu" onClick={toggleMenuVisibility} />
            {isMenuOpened ? (
                <nav className="mobile-dropdown-menu">
                    <section>
                        <ul className="sub-nav lang">
                            {AppService.accountType !== "surrogate" ? <li onClick={redirectTo}>Edit my profile</li> : ""}
                            <li onClick={doLogout}>Logout</li>
                        </ul>
                        {showPrivacyPolicy && (
                            <div className="policy-container">
                                <a
                                    href="https://www.conceiveabilities.com/privacy-policy/"
                                    target="_blank"
                                    aria-label="Open Privacy Policy"
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        )}
                    </section>
                </nav>
            ) : null}
        </nav>
    );
};

export default MobileMenuComponent;
