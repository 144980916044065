import React from "react";
import { isArray, isEqual } from "lodash/lang";
import i18next from "i18next";
import { Redirect, withRouter } from "react-router-dom";
import { AuthAndQuestionContext } from "../../../ProvideCombinedAuthAndQuestionContext";
import AppService from "../../../service/AppService/AppService";
import ProfileBannerComponent from "../../../components/ProfileBannerComponent/ProfileBannerComponent";
import clockIcon from "../../../resources/images/clock.svg";
import { UserSurveyStatus } from "../../../helper/Constants";

class StartApplication extends React.Component {
    static contextType = AuthAndQuestionContext;

    constructor(props) {
        super(props);
        this.state = {
            redirectToSurvey: false,
            redirectToProfile: false,
            questions: [],
            showCalendlyPopUp: false,
            isAppStarted: props.isAppStarted
        };
        this.startApplication = this.startApplication.bind(this);
        this.navigateToSurvey = this.navigateToSurvey.bind(this);
        this.startApplicationDesktop = this.startApplicationDesktop.bind(this);
    }

    startApplicationDesktop() {
        this.props.history.push("/survey");
        this.props.onAppStarted();
    }

    componentWillMount() {
        AppService.initAccountType();
        this.setState({ image: this.context.user?.avatarURL });
    }

    componentDidMount() {
        if (isArray(this.context.questionContext?.questions)) {
            this.setState({ questions: this.context.questionContext?.questions });
        }
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { context, state } = this;
        if (isArray(context.questionContext?.questions) && !isEqual(context.questionContext?.questions, state.questions)) {
            this.setState({ questions: this.context.questionContext?.questions, isAppStarted: AppService.isAppStarted });
        }
    }

    resize() {
        this.setState({ redirectToSurvey: false });
    }

    startApplication() {
        this.setState({ redirectToSurvey: false });
        this.props.onAppStarted();
    }

    navigateToSurvey() {
        if (this.state.isAppStarted || AppService.userSurveyStatus === UserSurveyStatus.COMPLETED) {
            this.setState({ redirectToSurvey: true });
        } else {
            this.props.onAppStarted();
        }
    }

    buildDesktopStartButton(surveyType) {
        if (this.state.isAppStarted !== null) {
            return (
                <button className={`start-button desktop-button bg-${surveyType}`} onClick={() => this.startApplicationDesktop()}>
                    {this.state.isAppStarted ? i18next.t("Continue application") : i18next.t("Start application")}
                </button>
            );
        }
    }

    buildBannerTitle(surveyType) {
        switch (surveyType) {
            case "egg_donor":
                if (this.state.isAppStarted === null) {
                    return " →";
                }
                return this.state.isAppStarted ? "Continue your application →" : "Begin your application →";
            case "parent":
                return "Ready to begin?";
            default:
                return null;
        }
    }

    buildBannerText(surveyType) {
        switch (surveyType) {
            case "parent":
                return (
                    <p>
                        <span>You can start with a few first questions, it will take about 2 minutes</span>
                        <span className="clock-wrapper">
                            <img src={clockIcon} /> 2 minutes
                        </span>
                    </p>
                );
            case "egg_donor":
                if (this.state.isAppStarted === null) {
                    return "";
                }
                return (
                    <p>
                        {!this.state.isAppStarted ? (
                            <>
                                <span>You can start with a few first questions, it will take about 2 minutes</span>
                                <span className="clock-wrapper">
                                    <img src={clockIcon} /> 2 minutes
                                </span>
                            </>
                        ) : (
                            <span>You can continue filling your survey.</span>
                        )}
                    </p>
                );
        }
    }

    buildBannerDescription(surveyType) {
        switch (surveyType) {
            case "egg_donor":
                return "We are excited to meet you. To get going, please fill out the egg donor application. If you are selected, we wil schedule a time to talk and give you more details about the process.";
            case "parent":
                return "We are excited to meet you.";
        }
    }

    render() {
        if (this.state.redirectToSurvey) {
            return <Redirect to="/survey" push={true} />;
        }
        if (this.state.redirectToProfile) {
            return <Redirect to="/profile" push={true} />;
        }
        if (AppService.isAppMuted) {
            return <Redirect to="/home" push={true} />;
        }

        const surveyType = this.context.authContext.user?.role.toLowerCase();
        if ("surrogate" === surveyType || "parent" === surveyType || "egg_donor" === surveyType) {
            switch (AppService.userSurveyStatus) {
                case UserSurveyStatus.COMPLETED:
                case UserSurveyStatus.ACTIVE_LIVE:
                case UserSurveyStatus.MATCHED:
                    return <Redirect to="/accepted" push={true} />;
                case UserSurveyStatus.REJECTED:
                case UserSurveyStatus.INITIAL_REJECTED:
                    return <Redirect to="/home" push={true} />;
            }
        }

        const title = () => {
            switch (surveyType) {
                case "egg_donor":
                case "parent":
                    return "We’re glad you are here!";
                default:
                    return "";
            }
        };

        return (
            <div>
                <div className="start-application">
                    <h1>{title()}</h1>
                    {surveyType !== "surrogate" && (
                        <ProfileBannerComponent
                            title={this.buildBannerTitle(surveyType)}
                            textContent={this.buildBannerText(surveyType)}
                            surveyType={surveyType}
                            navigateToSurvey={this.startApplicationDesktop}
                        />
                    )}
                    {<p className="banner-description">{this.buildBannerDescription(surveyType)}</p>}
                    {this.buildDesktopStartButton(surveyType)}
                </div>
            </div>
        );
    }
}

export default withRouter(StartApplication);
