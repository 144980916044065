import React from "react";
import "./InformationList.scss";
import closeButton from "../../../resources/images/close-left-bar.svg";
import cancelIcon from "../../../resources/images/cancel.svg";
import saveIcon from "../../../resources/images/save-icon.svg";
import editIcon from "../../../resources/images/edit-icon.svg";
import AppService from "../../../service/AppService/AppService";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import { getFullProfileQuestions } from "../../../requests/AdminAPIRequests/adminAPIRequests";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";
import { formatPhoneNumber } from "../../../shared/FormattingUtils/FormattingUtils";

function InformationList({ donor, setDonor, className, isAdmin }) {
    const editableAnswersList = ["TEXT", "PHONE", "NUMBER"];

    const parseAndBuildJsonData = () => {
        donor.questionAnswers.forEach((questionGroup) => {
            questionGroup.answers.forEach((answer) => {
                if (answer.type === "DYNAMIC_GROUP") {
                    const replacementMapping = {
                        "What is your sibling’s date of birth?": "Year of birth",
                        "What is sibling’s gender?": "Gender",
                        "What is your sibling’s height?": "Height",
                        "How is your sibling related to you?": "Family relation",
                        "Please share any of this sibling’s special skills and talents.": "Special skills, talents and abilities",
                        "Does this sibling have freckles?": "Freckles",
                        "What is this sibling's occupation?": "Occupation",
                        "What is this sibling’s occupation?": "Occupation",
                        "Is your sibling living or deceased?": "Living or deceased",
                        "What is the highest level of education this sibling has completed?": "Education",
                        "How old was he/she when he/she passed away?": "Age of death",
                        "What was the cause of death?": "Cause of death"
                    };

                    let heightFeet = "";
                    let heightInches = "";

                    const valueJson = JSON.parse(answer.answer);
                    answer.groupQuestionAnswers = [];

                    valueJson.forEach((valueJsonItem, groupIndex) => {
                        let groupObj = {
                            instanceGroupName: valueJsonItem.instanceGroupName,
                            groupNumber: groupIndex + 1,
                            answers: []
                        };

                        let answerSet = new Set();

                        valueJsonItem.groupWithAnswers.forEach((groupQuestion) => {
                            if (groupQuestion?.lastAnswersSelected?.length > 0) {
                                groupQuestion.lastAnswersSelected = groupQuestion.lastAnswersSelected.join(", ");
                            }
                            groupQuestion.answers.forEach((groupAnswer) => {
                                let questionText = replacementMapping[groupQuestion.text] || groupQuestion.text;
                                let answerValue = groupAnswer.userAnswer || groupQuestion.lastAnswersSelected;

                                if (
                                    ["What is your sibling’s height (Feet)?", "What is your sibling's height (Feet)?"].includes(
                                        groupQuestion.text
                                    )
                                ) {
                                    heightFeet = groupAnswer.userAnswer;
                                    return;
                                } else if (
                                    ["What is your sibling's height (Inches)?", "What is your sibling’s height (Inches)?"].includes(
                                        groupQuestion.text
                                    )
                                ) {
                                    heightInches = groupAnswer.userAnswer;
                                    return;
                                }

                                if (groupQuestion.type === "DATE") {
                                    answerValue = formatDate(answerValue);
                                }

                                let answerKey = {
                                    userAnswerId: null,
                                    question: questionText,
                                    answer: answerValue,
                                    type: groupQuestion.type,
                                    groupQuestionAnswers: null
                                };
                                let answerKeyString = JSON.stringify(answerKey);

                                answerSet.add(answerKeyString);
                            });
                        });

                        if (heightFeet || heightInches) {
                            let heightAnswer = `${heightFeet}' ${heightInches}"`;
                            let heightKey = {
                                userAnswerId: null,
                                question: "Height",
                                answer: heightAnswer,
                                type: "COMBINED_HEIGHT",
                                groupQuestionAnswers: null
                            };
                            answerSet.add(JSON.stringify(heightKey));
                        }

                        groupObj.answers = Array.from(answerSet).map((answerStr) => JSON.parse(answerStr));
                        answer.groupQuestionAnswers.push(groupObj);
                    });
                } else if (answer.question === "Previous Cycles") {
                    const valueJson = JSON.parse(answer.answer);
                    if (valueJson) {
                        answer.groupQuestionAnswers = parseAndBuildCycleJson(valueJson);
                    }
                }
            });
        });
    };

    const parseAndBuildCycleJson = (jsonData) => {
        return jsonData.map((item, index) => {
            const answers = [];

            const mappings = {
                date: "Retrieval Date",
                retrieved: "Eggs Retrieved",
                fert: "Eggs Fertilized",
                cryo: "Cryo-Preserved",
                is_conab: "ConceiveAbilities Cycle",
                preg_outcome: "Pregnancy Outcome",
                cryo_outcome: "Cryo Cycle Outcome",
                notes: "Internal Notes",
                cycle_notes: "Cycle Notes"
            };

            Object.keys(item).forEach((key) => {
                let answer = item[key];
                let question = mappings[key] || key;
                if (question === "Internal Notes") {
                    return;
                }
                if (typeof answer === "object" && answer !== null) {
                    answer = answer.text;
                }

                answers.push({
                    userAnswerId: null,
                    question,
                    answer: key === "date" ? formatDate(answer) : answer,
                    groupQuestionAnswers: null
                });
            });
            return {
                instanceGroupName: `Cycle`,
                groupNumber: index + 1,
                answers
            };
        });
    };

    const handleToggleClick = async (title) => {
        const index = donor.questionAnswers.findIndex((item) => item?.key === title);
        if (index !== -1) {
            donor.questionAnswers[index].extended = !donor.questionAnswers[index].extended;
            setDonor({ ...donor });
            if (donor.questionAnswers[index].answers.length === 0) {
                try {
                    donor.questionAnswers[index].isLoading = true;
                    const questionsResponse = await getFullProfileQuestions(donor.id, title);
                    if (questionsResponse.data) {
                        donor.questionAnswers[index].answers = questionsResponse.data.items;
                        parseAndBuildJsonData();
                    }
                } catch (e) {
                    donor.questionAnswers[index].isLoading = false;
                } finally {
                    donor.questionAnswers[index].isLoading = false;
                }
            }
            setDonor({ ...donor });
        }
    };

    const formatDate = (inputDate) => {
        if (!inputDate || inputDate === "") {
            return "";
        }
        const date = new Date(inputDate);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const editAnswer = (groupTitle, answerIndex) => {
        const index = donor.questionAnswers.findIndex((item) => item.title === groupTitle);
        if (index !== -1) {
            donor.questionAnswers[index].answers[answerIndex].edit = true;
            setDonor({ ...donor });
        }
    };

    const editGroupAnswer = (groupTitle, answerIndex, dynamicGroup) => {
        if (dynamicGroup !== undefined) {
            const { dIndex } = dynamicGroup;
            const editedDynamicGroup = donor.questionAnswers
                .flatMap((s) => s.answers)
                .filter((q) => q.questionType === "DYNAMIC_GROUP" && q.key === groupTitle);
            editedDynamicGroup[0].groupQuestionAnswers[dIndex].answers[answerIndex].edit = true;
            setDonor({ ...donor });
            return;
        }
        const group = donor.questionAnswers.flatMap((s) => s.answers).filter((q) => q.questionType === "GROUP" && q.key === groupTitle);
        if (group.length === 1) {
            group[0].groupQuestionAnswers[answerIndex].edit = true;
            setDonor({ ...donor });
        }
    };

    const onCancelEdit = (groupTitle, answerIndex) => {
        const index = donor.questionAnswers.findIndex((item) => item.title === groupTitle);
        if (index !== -1) {
            donor.questionAnswers[index].answers[answerIndex].edit = false;
            donor.questionAnswers[index].answers[answerIndex].editedValue = undefined;
            setDonor({ ...donor });
        }
    };

    const onCancelGroupEdit = (groupTitle, answerIndex, dynamicGroup) => {
        if (dynamicGroup !== undefined) {
            const { dIndex } = dynamicGroup;
            const editedDynamicGroup = donor.questionAnswers
                .flatMap((s) => s.answers)
                .filter((q) => q.questionType === "DYNAMIC_GROUP" && q.key === groupTitle);
            editedDynamicGroup[0].groupQuestionAnswers[dIndex].answers[answerIndex].edit = false;
            editedDynamicGroup[0].groupQuestionAnswers[dIndex].answers[answerIndex].editedValue = undefined;
            setDonor({ ...donor });
            return;
        }
        const group = donor.questionAnswers.flatMap((s) => s.answers).filter((q) => q.questionType === "GROUP" && q.key === groupTitle);
        if (group.length === 1) {
            group[0].groupQuestionAnswers[answerIndex].edit = false;
            group[0].groupQuestionAnswers[answerIndex].editedValue = undefined;
            setDonor({ ...donor });
        }
    };

    const onSaveAnswer = (groupTitle, answerIndex, userAnswerId) => {
        const index = donor.questionAnswers.findIndex((item) => item.title === groupTitle);
        if (index !== -1 && donor.questionAnswers[index].answers[answerIndex].editedValue) {
            donor.questionAnswers[index].answers[answerIndex].value = donor.questionAnswers[index].answers[answerIndex].editedValue;
            delete donor.questionAnswers[index].answers[answerIndex].editedValue;

            const body = donor.questionAnswers[index].answers[answerIndex].value;
            AppService.getAxiosWithRawBody()
                .put(`/api/user-with-answers/admin/answer/${userAnswerId}/update/`, body)
                .catch((e) => console.log(e));
        }

        donor.questionAnswers[index].answers[answerIndex].edit = false;
        setDonor({ ...donor });
    };

    const onSaveGroupAnswer = (groupTitle, answerIndex, userAnswerId, dynamicGroup) => {
        if (dynamicGroup !== undefined) {
            onSaveDynamicGroup(groupTitle, answerIndex, dynamicGroup.dIndex);
            return;
        }
        const group = donor.questionAnswers.flatMap((s) => s.answers).filter((q) => q.questionType === "GROUP" && q.key === groupTitle);
        if (group.length === 1 && group[0].groupQuestionAnswers[answerIndex].editedValue) {
            group[0].groupQuestionAnswers[answerIndex].value = group[0].groupQuestionAnswers[answerIndex].editedValue;
            delete group[0].groupQuestionAnswers[answerIndex].editedValue;

            const body = group[0].groupQuestionAnswers[answerIndex].value;
            AppService.getAxiosWithRawBody()
                .put(`/api/user-with-answers/admin/answer/${userAnswerId}/update/`, body)
                .catch((e) => console.log(e));
        }
        group[0].groupQuestionAnswers[answerIndex].edit = false;
        setDonor({ ...donor });
    };

    const onSaveDynamicGroup = (groupTitle, answerIndex, dIndex) => {
        const group = donor.questionAnswers
            .flatMap((s) => s.answers)
            .filter((q) => q.questionType === "DYNAMIC_GROUP" && q.key === groupTitle);
        if (group.length === 1 && group[0].groupQuestionAnswers[dIndex].answers[answerIndex].editedValue) {
            const answerValueToSave = group[0].groupQuestionAnswers[dIndex].answers[answerIndex].editedValue;
            const parsedJson = JSON.parse(group[0].value);
            parsedJson[dIndex].groupWithAnswers[answerIndex].answers[0].userAnswer = answerValueToSave;
            delete group[0].groupQuestionAnswers[dIndex].answers[answerIndex].editedValue;
            const body = JSON.stringify(parsedJson);

            AppService.getAxiosWithRawBody()
                .put(`/api/user-with-answers/admin/answer/${group[0].userAnswerId}/update/`, body)
                .catch((e) => console.error(e));
            group[0].groupQuestionAnswers[dIndex].answers[answerIndex].edit = false;
            group[0].groupQuestionAnswers[dIndex].answers[answerIndex].value = answerValueToSave;
            group[0].value = body;
            setDonor({ ...donor });
        }
    };

    const handleChangeAnswer = (e, groupTitle, answerIndex, isPhone) => {
        const index = donor.questionAnswers.findIndex((item) => item.title === groupTitle);
        const editedAnswer = isPhone ? e : e.target.value;
        if (index !== -1) {
            donor.questionAnswers[index].answers[answerIndex].editedValue = editedAnswer;
            setDonor({ ...donor });
        }
    };

    const handleChangeGroupAnswer = (e, groupTitle, answerIndex, isPhone, dynamicGroup) => {
        const editedAnswer = isPhone ? e : e.target.value;
        if (dynamicGroup !== undefined) {
            const { dIndex } = dynamicGroup;
            const editedDynamicGroup = donor.questionAnswers
                .flatMap((s) => s.answers)
                .filter((q) => q.questionType === "DYNAMIC_GROUP" && q.key === groupTitle);
            editedDynamicGroup[0].groupQuestionAnswers[dIndex].answers[answerIndex].editedValue = editedAnswer;
            setDonor({ ...donor });
            return;
        }

        const group = donor.questionAnswers.flatMap((s) => s.answers).filter((q) => q.questionType === "GROUP" && q.key === groupTitle);
        if (group.length === 1) {
            group[0].groupQuestionAnswers[answerIndex].editedValue = editedAnswer;
            setDonor({ ...donor });
        }
    };

    const removeOnlyAdminCategories = (categories = []) => {
        categories.forEach((category) => {
            const index = donor.questionAnswers.findIndex((item) => item?.title === category);
            if (index !== -1) {
                delete donor.questionAnswers[index];
            }
        });
    };

    const contactIndex = donor.questionAnswers.findIndex((item) => item?.key === "Contact");
    if (contactIndex !== -1) {
        const photoIndex = donor.questionAnswers[contactIndex].answers.findIndex((item) => item?.key === "Photo");
        delete donor.questionAnswers[contactIndex].answers[photoIndex];
    }
    !isAdmin && removeOnlyAdminCategories(["DonorAdminOnly__c", "DonorRisk__c", "DonorGenetic__c"]);

    const showEditButton = (answer) => {
        return isAdmin && editableAnswersList.includes(answer.questionType);
    };

    const getEditableInput = (title, answer, answerIndex, isGroup, dynamicGroup) => {
        switch (answer.questionType) {
            case "TEXT":
                return answer.value.length < 100 ? (
                    <input
                        type="text"
                        value={answer.editedValue?.length >= 0 ? answer.editedValue : answer.value}
                        className="edit-input"
                        onChange={
                            isGroup
                                ? (e) => handleChangeGroupAnswer(e, title, answerIndex, false, dynamicGroup)
                                : (e) => handleChangeAnswer(e, title, answerIndex)
                        }
                    />
                ) : (
                    <textarea
                        rows="3"
                        value={answer.editedValue?.length >= 0 ? answer.editedValue : answer.value}
                        className="edit-input"
                        onChange={
                            isGroup
                                ? (e) => handleChangeGroupAnswer(e, title, answerIndex, false, dynamicGroup)
                                : (e) => handleChangeAnswer(e, title, answerIndex)
                        }
                    ></textarea>
                );
            case "PHONE":
                return (
                    <div className="edit-phone-field">
                        <PhoneInput
                            country={"us"}
                            value={answer.editedValue?.length >= 0 ? answer.editedValue : answer.value}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            onChange={
                                isGroup
                                    ? (e) => handleChangeGroupAnswer(e, title, answerIndex, true, dynamicGroup)
                                    : (e) => handleChangeAnswer(e, title, answerIndex, true)
                            }
                        />
                    </div>
                );
            case "NUMBER":
                return (
                    <input
                        type="number"
                        value={answer.editedValue?.length >= 0 ? answer.editedValue : answer.value}
                        className="edit-input"
                        onChange={
                            isGroup
                                ? (e) => handleChangeGroupAnswer(e, title, answerIndex, false, dynamicGroup)
                                : (e) => handleChangeAnswer(e, title, answerIndex)
                        }
                    />
                );
        }
    };

    const getAnswersClassNames = (group) => {
        return classNames("answers", {
            extended: group?.extended
        });
    };

    const formatCurrency = (amount) => {
        if (/\$\d+/.test(amount)) {
            const number = parseFloat(amount.replace("$", ""));
            return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number);
        }
        return amount;
    };

    const getNewAnswerValues = (answer) => {
        let value;
        if (!answer.relation) {
            if (answer.type === "PHONE") {
                value = formatPhoneNumber(answer.answer);
            } else if (/\$\d+/.test(answer.answer)) {
                value = formatCurrency(answer.answer);
            } else {
                value = answer.answer;
            }
            return { key: answer.question, value };
        }
    };

    const getAnswerRow = (title, answer, answerIndex, isGroup, dynamicGroup) => {
        if (answer.answer.length === 0 || answer.answer === "null") {
            return;
        }
        const { key, value } = getNewAnswerValues(answer);
        return (
            <div className={answerClasses(answerIndex)} key={answerIndex}>
                <div className={answerDirectionClasses()}>
                    <span className={isYesNoQuestion(answer)}>{key}</span>
                    {showEditButton(answer) && answer.edit ? (
                        getEditableInput(title, answer, answerIndex, isGroup, dynamicGroup)
                    ) : (
                        <span className={isYesNoValue(answer)}>{value}</span>
                    )}
                </div>
                {editOption(title, answer, answerIndex, isGroup, dynamicGroup)}
            </div>
        );
    };

    const editOption = (title, answer, answerIndex, isGroup, dynamicGroup) => {
        return (
            showEditButton(answer) && (
                <div className="admin-icons">
                    {answer.edit ? (
                        <>
                            <img
                                src={cancelIcon}
                                alt="cancel-editing"
                                onClick={
                                    isGroup
                                        ? () => onCancelGroupEdit(title, answerIndex, dynamicGroup)
                                        : () => onCancelEdit(title, answerIndex)
                                }
                            />
                            <img
                                src={saveIcon}
                                alt="save-answer"
                                onClick={
                                    isGroup
                                        ? () => onSaveGroupAnswer(title, answerIndex, answer.userAnswerId, dynamicGroup)
                                        : () => onSaveAnswer(title, answerIndex, answer.userAnswerId)
                                }
                            />
                        </>
                    ) : (
                        <img
                            src={editIcon}
                            alt="edit-answer"
                            className="edit-icon"
                            onClick={
                                isGroup ? () => editGroupAnswer(title, answerIndex, dynamicGroup) : () => editAnswer(title, answerIndex)
                            }
                        />
                    )}
                </div>
            )
        );
    };

    const answerClasses = (index) => {
        return classNames("answer", {
            "answer-border": index > 0,
            "": index === 0
        });
    };

    const answerDirectionClasses = () => {
        return classNames("answer-row-direction", {
            "admin-answer-width": isAdmin
        });
    };

    const isYesNoQuestion = (answer) => {
        return classNames({
            "yes-no-question": answer.questionType === "YES_NO",
            "answer-title": answer.questionType !== "YES_NO"
        });
    };

    const isYesNoValue = (answer) => {
        return classNames({
            "yes-no-value": answer.questionType === "YES_NO",
            "answer-value": answer.questionType !== "YES_NO"
        });
    };
    return (
        <ul className={classNames("profile-information", className)}>
            {donor.questionAnswers &&
                donor.questionAnswers.map((group, groupIndex) => (
                    <li key={groupIndex} className="group">
                        <div className="group-action" onClick={() => handleToggleClick(group.key)}>
                            <span className="group-title">{group.displayName}</span>
                            <div className="accordion-wrapper">
                                {group.isLoading && <Spinner />}
                                <img className={`arrow ${group.extended ? "extended" : ""}`} src={closeButton} alt="show/hide group" />
                            </div>
                        </div>
                        <div className={getAnswersClassNames(group)}>
                            {group.answers.map((answer, answerIndex) =>
                                answer.type === "GROUP" || answer.groupName ? (
                                    <div className="group-content">
                                        <div className="group-question">
                                            <div className="group-question-title">
                                                <span>{answer.groupName}</span>
                                            </div>
                                            {answer.groupItems.map((gAnswer, gAnswerIndex) =>
                                                getAnswerRow(answer.question, gAnswer, gAnswerIndex, true)
                                            )}
                                        </div>
                                    </div>
                                ) : answer.type === "DYNAMIC_GROUP" ||
                                  (answer.question === "Previous Cycles" && answer.groupQuestionAnswers) ? (
                                    <div className="dynamic-group" key={answerIndex}>
                                        <div className="dynamic-title">{answer.question}</div>
                                        <div className="group-content">
                                            {answer.groupQuestionAnswers.map((dGroup, dIndex) => (
                                                <div className="group-question" key={dIndex}>
                                                    <span className="group-question-title">
                                                        {`${dGroup.instanceGroupName ? dGroup.instanceGroupName : ""} ` +
                                                            dGroup.groupNumber}
                                                    </span>
                                                    {dGroup.answers.map((dAnswer, dAnswerIndex) =>
                                                        getAnswerRow(answer.question, dAnswer, dAnswerIndex, true)
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    getAnswerRow(group.key, answer, answerIndex, false)
                                )
                            )}
                        </div>
                    </li>
                ))}
        </ul>
    );
}

export default InformationList;
