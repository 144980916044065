import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { AuthContext } from "../../AuthContext";
import Layout from "@components/appLayout/AppLayoutComponent";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SurveyProfileAside from "../../pages/surveyProfile/SurveyProfileAside/SurveyProfileAside";
import SurveyProfileFilters from "../../pages/surveyProfile/SurveyProfileFilters/SurveyProfileFilters";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";
import Persons from "../persons/Persons";
import AppService from "../../service/AppService/AppService";
import ReservedBanner from "../../pages/ReservedDonors/ReservedBanner/ReservedBanner";
import EmptyListComponent from "../emptyListComponent/EmptyListComponent";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";

function EggDonorListComponent({ loadItems, oneLoadCount, onlyFavouriteDonors, showReservedBanner }) {
    const { user, setUser } = useContext(AuthContext);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [eggDonors, changeEggDonors] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalDonors, seTotalDonors] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [filterParams, setFilterParams] = useState(JSON.parse(sessionStorage.getItem("filter-params")) || {});
    const [error, setError] = useState();
    const [destination, setDestination] = useState("");
    const [isFiltersApplied, setFiltersApplied] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const infiniteScrollRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (infiniteScrollRef.current) {
                sessionStorage.setItem("scrollPosition", infiniteScrollRef.current.scrollTop.toString());
            }
        };

        const currentScrollRef = infiniteScrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (currentScrollRef) {
                currentScrollRef.removeEventListener("scroll", handleScroll);
            }
            if (!history.location.pathname.includes("/donor")) {
                sessionStorage.removeItem("oneLoadCount");
                sessionStorage.removeItem("pageNumber");
                sessionStorage.removeItem("scrollPosition");
            }
        };
    }, [history.location.pathname]);

    useEffect(() => {
        const savedScrollPosition = sessionStorage.getItem("scrollPosition");
        if (savedScrollPosition && infiniteScrollRef.current && eggDonors.length > 0) {
            infiniteScrollRef.current.scrollTop = parseInt(savedScrollPosition, 10);
        }
        if (parseInt(savedScrollPosition) <= 36) {
            sessionStorage.removeItem("oneLoadCount");
            sessionStorage.removeItem("pageNumber");
        }
    }, [infiniteScrollRef.current, eggDonors.length]);

    useEffect(() => {
        if (isDataLoaded) {
            sessionStorage.setItem("oneLoadCount", String((pageNumber - 1) * oneLoadCount));
            sessionStorage.setItem("pageNumber", String(pageNumber - 1));
        }
    }, [pageNumber]);

    useEffect(() => {
        const path = history.location.pathname;
        initDestination(path);
        if (sessionStorage.getItem("filter-params")) {
            setFiltersApplied(isAnyFieldFilled(JSON.parse(sessionStorage.getItem("filter-params"))));
        }
    }, []);
    const applyFavorite = async (donors) => {
        const responseFavorite = await AppService.getAxios().get(`/api/user/favorite/${user?.id}`);
        const favoriteDonors = responseFavorite.data;
        setUser((prevUser) => ({
            ...prevUser,
            favoriteCount: favoriteDonors.length
        }));

        const donorsWithLike = donors.map((donor) => {
            if (favoriteDonors.some((person) => person.favoriteUserMultiKey.favoriteUserId === donor.id)) {
                return { ...donor, liked: true };
            }
            return donor;
        });
        return onlyFavouriteDonors ? removeUnlikedDonors(donorsWithLike, favoriteDonors) : donorsWithLike;
    };

    const initDestination = (path) => {
        switch (path) {
            case "/favorite":
                setDestination("FAVORITE");
                break;
            case "/reserved":
                setDestination("RESERVED");
                break;
            case "/donors":
                setDestination("ALL");
                break;
            default:
                setDestination("UNKNOWN");
                break;
        }
    };

    const removeUnlikedDonors = (donors, favoriteDonors) => {
        return donors.filter((person) => {
            if (favoriteDonors.some((el) => el.favoriteUserMultiKey.favoriteUserId === person.id)) {
                return { ...person };
            }
        });
    };

    const prepareFilters = (filterParams) => {
        const listParams = {};
        const singleParams = {};
        if (filterParams) {
            for (let key of Object.keys(filterParams)) {
                if (Array.isArray(filterParams[key])) {
                    if (filterParams[key].length > 0) {
                        listParams[key] = filterParams[key];
                    }
                } else {
                    if (filterParams[key].length > 0) {
                        singleParams[key] = filterParams[key];
                    }
                }
            }
        }
        return { listParams, singleParams };
    };

    const loadMore = async () => {
        setLoading(true);
        const filters = prepareFilters(filterParams);
        try {
            let page = pageNumber;
            let count = oneLoadCount;
            if (!isDataLoaded) {
                page = Number(sessionStorage.getItem("pageNumber"));
                count = Number(sessionStorage.getItem("oneLoadCount"));
            }
            const response = await loadItems(pageNumber, filters, count);
            setTotalValue(response);
            const donors = await applyFavorite(response.data.entries);
            setIsDataLoaded(true);
            changeEggDonors([...eggDonors, ...donors]);
            const totalCountOfPages = Math.ceil(response.data.total / oneLoadCount);
            setHasNextPage(page < totalCountOfPages);
            setPageNumber(page + 1);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const [infiniteRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: "0px 0px 600px 0px"
    });

    const applyFilter = async (filterParams) => {
        sessionStorage.setItem("filter-params", JSON.stringify(filterParams));
        setFilterParams({
            ...filterParams
        });
        setLoading(true);
        setHasNextPage(true);
        const filters = prepareFilters(filterParams);
        try {
            setPageNumber(2);
            const response = await loadItems(1, filters);
            const donors = await applyFavorite(response.data.entries);
            setTotalValue(response);

            const totalCountOfPages = Math.ceil(response.data.total / oneLoadCount);
            setHasNextPage(pageNumber <= totalCountOfPages);
            setIsDataLoaded(true);
            changeEggDonors(donors);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const setTotalValue = (response) => {
        seTotalDonors(response.data.total);
    };

    const removeFilterItem = async (key, value) => {
        const resetFilterParams = { ...filterParams };
        if (Array.isArray(resetFilterParams[key])) {
            resetFilterParams[key] = resetFilterParams[key].filter((filter) => filter !== value);
        } else {
            resetFilterParams[key] = "";
        }
        await applyFilter(resetFilterParams);
        if (!isAnyFieldFilled(resetFilterParams)) {
            setFiltersApplied(false);
        }
    };
    const isAnyFieldFilled = (obj) => {
        for (let key in obj) {
            const value = obj[key];

            if (Array.isArray(value) && value.length > 0) {
                return true;
            }

            if (typeof value === "string" && value.trim() !== "") {
                return true;
            }
        }
        return false;
    };

    const getProfilesCount = () => {
        if (totalDonors && totalDonors > 0) {
            const text = totalDonors === 1 ? "profile" : "profiles";
            return <div className="persons-count">{`${totalDonors} ${text}`}</div>;
        }
        return "";
    };

    return (
        <Layout
            isContentLoaded={eggDonors.length > 0}
            isScrollBlocked={pageNumber === 0 || pageNumber === 1}
            infiniteScroll={true}
            left={
                <SurveyProfileAside
                    onChange={applyFilter}
                    setFiltersApplied={setFiltersApplied}
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                />
            }
            center={
                <div className="infinite-scroll" ref={infiniteScrollRef}>
                    <SurveyProfileFilters isFiltersApplied={isFiltersApplied} filterParams={filterParams} removeFilter={removeFilterItem} />
                    {getProfilesCount()}
                    <div className="survey-profile">
                        <Persons eggDonors={eggDonors} changeEggDonors={changeEggDonors} />
                        {showReservedBanner && eggDonors.length > 0 && <ReservedBanner />}
                    </div>
                    {hasNextPage && (
                        <div ref={infiniteRef} className="loading">
                            <Spinner />
                        </div>
                    )}
                    {!hasNextPage && eggDonors.length === 0 && (
                        <EmptyListComponent destination={destination} filtersSelected={filterParams} />
                    )}
                </div>
            }
            right={null}
            showRightColumn={false}
        />
    );
}

export default EggDonorListComponent;
