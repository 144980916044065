import React, { useContext, useEffect, useState } from "react";
import AppService from "@service/AppService/AppService";
import Hint from "@components/hint/HintComponent";
import PhoneInput from "react-phone-input-2";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { getFeedback } from "@shared/Feedback";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames";
import { surveyQuestionMethods } from "../../../../shared/Questions/Questions";
import { validatePhoneNumber } from "../../../../requests/surveyAPIRequests/surveyAPIRequests";
import { SurveyQuestionsContext } from "../../../../SurveyQuestionsStore";

function PhoneComponent({ selectedTopic, question, onAnswer, showOverlay, readonly, className, isDynamicGroup }) {
    const questionContext = useContext(SurveyQuestionsContext);
    const [wasFull, setWasFull] = useState(false);
    const [phoneIsValid, setPhoneIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const userAnswer = AppService.surveyId && !isDynamicGroup ? "value" : "userAnswer";
    const isGCUser = AppService.accountType === "surrogate";

    useEffect(() => {
        const value = question.answers[0].userAnswer;
        if (value?.length > 0 && phoneIsValid) {
            validatePhoneForGCUsers(value);
        }
    }, []);

    useEffect(() => {
        questionContext?.toggleValidationPhoneNumber(phoneIsValid);
    }, [phoneIsValid]);

    const setValue = (value, answerId) => {
        const answerIndex = question.answers.findIndex((a) => a.id === answerId);
        question.answers[answerIndex][userAnswer] = value;
        AppService.updateProgress = true;
    };

    const handlePhoneChange = async (phone, country, answer) => {
        const formatLength = country.format.split("").filter((char) => char === ".").length;
        const isMaxInputLength = phone.length > 11;

        if (phone.length === 1) {
            setValue("", answer.id);
            !isGCUser && onAnswer(selectedTopic, question, answer);
            return;
        }
        if (isMaxInputLength) {
            getFeedback(answer);
            setValue(phone, answer.id);
            onAnswer(selectedTopic, question, answer);
            return;
        }
        if (phone && phone.length > formatLength) {
            setWasFull(true);
            return;
        }

        if (phone && phone.length !== formatLength) {
            setWasFull(false);
        } else if (!wasFull) {
            getFeedback(answer);
            setValue(phone, answer.id);
            onAnswer(selectedTopic, question, answer);
            await validatePhoneForGCUsers(phone);
            setWasFull(true);
        }
    };

    const validatePhoneForGCUsers = async (phoneValue) => {
        if (!isGCUser || loading) return;
        if (phoneValue === localStorage.getItem("current-phone")) {
            setPhoneIsValid(false);
            return;
        }

        try {
            questionContext.toggleLoadingValidation(false);
            setLoading(true);
            const response = await validatePhoneNumber(phoneValue);
            if (response.status === 200) {
                setPhoneIsValid(true);
            }
        } catch (err) {
            if (err.response.data.code && err.response.data.code === 400) {
                const message = err.response.data.message;
                if (message === "Current phone is not valid US phone number") {
                    localStorage.setItem("current-phone", phoneValue);
                    setPhoneIsValid(false);
                }
            } else {
                setPhoneIsValid(true);
            }
        } finally {
            setLoading(false);
            questionContext.toggleLoadingValidation(true);
        }
    };

    const buildPhoneInput = (answer, index) => {
        const isEdOrIp = surveyQuestionMethods.isEdOrIpAccountType();
        const regions = isEdOrIp ? ["america", "europe", "asia", "oceania", "africa"] : ["america"];
        const onlyCountries = isEdOrIp ? [] : ["us"];
        return (
            <div className="phone-container" key={index}>
                <PhoneInput
                    country={"us"}
                    value={answer?.userAnswer || "+1"}
                    onChange={(phone, country) => handlePhoneChange(phone, country, answer)}
                    countryCodeEditable={isEdOrIp}
                    disableDropdown={!isEdOrIp}
                    placeholder=""
                    regions={regions}
                    onlyCountries={onlyCountries}
                    inputProps={{
                        id: getTranslatedTextIfNeeded(question.text)
                    }}
                />
            </div>
        );
    };

    const feedbackItems = [];
    const answers = question.answers.map((a, i) => {
        const feedback = getFeedback(a);
        if (feedback) {
            feedback.forEach((f) => feedbackItems.push(f));
        }
        return buildPhoneInput(a, i);
    });

    return (
        <div>
            <div className={classNames("survey-question", className, { [`unanswered-border-${AppService.accountType}`]: !phoneIsValid })}>
                {readonly && <div className="answer-overlay" />}
                {showOverlay && <div className="survey-overlay" />}
                <label className="question-text" htmlFor={getTranslatedTextIfNeeded(question.text)}>
                    {getTranslatedTextIfNeeded(question.text)}
                </label>
                <div className="question-answers">
                    <div className="content">{answers}</div>
                </div>
                <Hint hint={getTranslatedTextIfNeeded(question.hint)} />
            </div>
            {feedbackItems}
        </div>
    );
}

export default PhoneComponent;
