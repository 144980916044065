import React from "react";
import "./RegistrationFormComponent.scss";
import PasswordTextBox from "@components/passwordTextBox/PasswordTextBoxComponent";
import i18n from "i18next";
import AppService from "@service/AppService/AppService";
import LiteSurvey from "@pages/liteSurvey/LiteSurvey";
import classNames from "classnames";

class RegistrationFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            firstName: "",
            lastName: "",
            email: "",
            isFormValid: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.isInternationalized = this.isInternationalized.bind(this);
    }

    handlePasswordChange(password) {
        this.setState({ password });
    }

    handleChange = ({ target }) => {
        this.setState({ [target.name]: target.value });
    };

    validateForm = () => {
        return this.isFullNameValid() && this.isEmailValid() && this.isPasswordValid();
    };

    isFullNameValid = () => {
        const { firstName, lastName } = this.state;

        return firstName.trim().length > 0 && lastName.trim().length > 0;
    };

    isEmailValid = () => {
        const { email } = this.state;
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
            email
        );
    };

    isPasswordValid = () => {
        const passwordInfo = this.getPasswordInfo();
        return (
            passwordInfo.isContainLowerLetter &&
            passwordInfo.isContainNumbers &&
            passwordInfo.isContainUpperLetter &&
            passwordInfo.isLengthValid
        );
    };

    getPasswordInfo = () => {
        const password = this.state.password;
        return {
            isLengthValid: password.length > 7,
            isContainUpperLetter: /[A-Z]/.test(password),
            isContainLowerLetter: /[a-z]/.test(password),
            isContainNumbers: /[0-9]/.test(password)
        };
    };

    saveProfile = () => {
        const { firstName, lastName, email, password } = this.state;
        if (this.validateForm() && !this.props.showProgress) {
            this.props.onSubmit({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email,
                password
            });
        }
    };

    selectCheckboxStyles() {
        switch (this.props.page) {
            case "surrogate":
                return "colors-text-surrogate";
            case "parent":
                return "colors-text-parent";
            case "egg_donor":
                return "colors-text-egg_donor";
        }
        return null;
    }

    isInternationalized(internationalized) {
        this.props.getShowToggle(internationalized);
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        this.saveProfile();
    };

    render() {
        const isFormValid = this.validateForm();
        const passwordInfo = this.getPasswordInfo();

        if (AppService.accountType === "surrogate") {
            return (
                <div className="form">
                    <LiteSurvey getInternationalized={this.isInternationalized} />
                    <div className="sign-in">
                        <label>{i18n.t("Already have an account?")}</label>
                        <a href="/app/login" className={`colors-text-${AppService.accountType}`}>
                            {i18n.t("Sign In")}
                        </a>
                    </div>
                </div>
            );
        }
        return (
            <form onSubmit={this.handleFormSubmit} className="form">
                <div className="form-item">
                    <label htmlFor="lblFirstName">{i18n.t("First Name")}</label>
                    <input type="text" id="lblFirstName" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                </div>
                <div className="form-item">
                    <label htmlFor="lblLastName">{i18n.t("Last Name")}</label>
                    <input type="text" id="lblLastName" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                </div>
                <div className="form-item">
                    <label htmlFor="lblEmail">{i18n.t("Email")}</label>
                    <input type="text" id="lblEmail" name="email" value={this.state.email} onChange={this.handleChange} />
                </div>
                <PasswordTextBox id="lblPassword" onPasswordChange={this.handlePasswordChange} title={i18n.t("Password")} />
                <div className="password-description">
                    <p>{i18n.t("Your password must be")}:</p>
                    <p>
                        <b id="lengthCheck" className={`${passwordInfo.isLengthValid ? this.selectCheckboxStyles() : null}`}>
                            &#10004;
                        </b>
                        &nbsp;<b>{i18n.t("at least 8 characters long")}</b>
                    </p>
                    <p>
                        <b id="upperCheck" className={`${passwordInfo.isContainUpperLetter ? this.selectCheckboxStyles() : null}`}>
                            &#10004;
                        </b>
                        &nbsp;{i18n.t("contain")} <b>{i18n.t("uppercase")}</b> {i18n.t("letters")}
                    </p>
                    <p>
                        <b id="lowerCheck" className={`${passwordInfo.isContainLowerLetter ? this.selectCheckboxStyles() : null}`}>
                            &#10004;
                        </b>
                        &nbsp;{i18n.t("contain")} <b>{i18n.t("lowercase")}</b> {i18n.t("letters")}
                    </p>
                    <p>
                        <b id="numberCheck" className={`${passwordInfo.isContainNumbers ? this.selectCheckboxStyles() : null}`}>
                            &#10004;
                        </b>
                        &nbsp;{i18n.t("contain")} <b>{i18n.t("numbers")}</b>
                    </p>
                </div>
                {this.props.showError && (
                    <div className="register-error">
                        <div>{this.props.errorMessage}</div>
                    </div>
                )}
                <button
                    type="submit"
                    className={classNames("join-button", { disabled: !isFormValid, [`bg-${AppService.accountType}`]: isFormValid })}
                    disabled={!isFormValid}
                >
                    {i18n.t("Start")}
                </button>
                {this.props.page === "surrogate" ? (
                    <div className="description-below">
                        <label>
                            Our job is to help you realize your dream of becoming a surrogate with expert guidance, starting with helping
                            you learn about the
                        </label>
                        <a href="https://www.conceiveabilities.com/surrogates/become-a-surrogate-mother/" className="colors-text-surrogate">
                            {" "}
                            surrogate process.
                        </a>
                        <label>
                            {" "}
                            Our proven process protects your dream of helping another family and makes it a reality. We are all-in as your
                            guide for a healthy and successful surrogacy journey.
                        </label>
                    </div>
                ) : null}
                {this.props.page === "parent" ? (
                    <div className="sign-in sign-in-parent">
                        <label>Have you registered with us already and want to view our egg donors?</label>
                        <a href="/app/login" className={`colors-text-${AppService.accountType}`}>
                            Search now
                        </a>
                    </div>
                ) : (
                    <div className="sign-in">
                        <label>{this.props.page === "surrogate" ? i18n.t("Already have an account?") : "Already have an account?"}</label>
                        <a href="/app/login" className={`colors-text-${AppService.accountType}`}>
                            {this.props.page === "surrogate" ? i18n.t("Sign In") : "Sign In"}
                        </a>
                    </div>
                )}
            </form>
        );
    }
}

export default RegistrationFormComponent;
