import React, { useContext, useEffect } from "react";
import { animateScroll as scroll, Element, scroller } from "react-scroll";
import AppService from "../../../service/AppService/AppService";
import { getTranslatedTextIfNeeded } from "../../../shared/InternationalizationTextUtils";
import Feedback from "@components/feedback/FeedbackComponent";
import UnansweredHintComponent from "@components/surveyQuestions/answerHint/UnansweredHintComponent";
import { unansweredQuestions } from "../../../shared/AnswersTracker/AnswersTracker";
import Question from "../../Question/Question";
import { SurveyQuestionsContext } from "../../../SurveyQuestionsStore";
import { UserSurveyStatus } from "../../../helper/Constants";
import SpinnerLoader from "../../SpinnerLoader/SpinnerLoader";
import { AuthContext } from "../../../AuthContext";
import { surveyQuestionMethods } from "../../../shared/Questions/Questions";

function QuestionElements({
    questions,
    selectedTopic,
    onAnswer,
    updateProgress,
    showUnansweredWarning,
    isDynamicGroup,
    dynamicQuestion,
    instanceId
}) {
    const authContext = useContext(AuthContext);
    const questionContext = useContext(SurveyQuestionsContext);
    const containerId = AppService.isMobile() ? "mobile-survey-container" : "survey-container";

    useEffect(() => {
        if (
            !authContext.isSurveyFinished() &&
            !questionContext.stopScrolling &&
            !isDynamicGroup &&
            questions?.length > 0 &&
            isSurveyReadyToScroll()
        ) {
            setTimeout(() => {
                scrollAfterQuestionLoaded();
                questionContext.setSurveyScroll(true);
            }, 900);
        }
    }, [selectedTopic, questions, questionContext.isSurveyLoaded, questionContext.stopScrolling]);

    const scrollAfterQuestionLoaded = () => {
        const { questions, questionIndex, lastSectionPassed } = questionContext;
        questionIndex >= 0 && scrollToLastAnsweredQuestion();
        questionIndex === -1 && scrollToLastQuestion();
    };

    const isSurveyReadyToScroll = () => {
        const isQuestionsHaveDynamicGroup = questions.some(
            (question) => question.type === "DYNAMIC_GROUP" && AppService.hiddenQuestions[selectedTopic.id]?.includes(question.id)
        );
        return isQuestionsHaveDynamicGroup ? questionContext.isSurveyLoaded : true;
    };

    const scrollToLastAnsweredQuestion = () => {
        const { questionIndex } = questionContext;
        const questionId = questions[questionIndex]?.sequenceId;
        scroller.scrollTo(questionId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            containerId
        });
    };

    const scrollToLastQuestion = () => {
        scroll.scrollToBottom({
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            containerId
        });
    };

    const getFeedbackOrDisqualify = (question) => {
        if (question.type === "GROUP" || question.type === "DYNAMIC_GROUP") {
            return null;
        }
        let review = null;
        if (question[surveyQuestionMethods.lastAnswerListName()]) {
            question[surveyQuestionMethods.lastAnswerListName()].forEach((id) => {
                const answer = question.answers.find((a) => a.id === id || a.text === id);
                if (answer && answer?.feedback?.text) {
                    const feedback = {
                        title: getTranslatedTextIfNeeded(answer.feedback.title),
                        text: getTranslatedTextIfNeeded(answer.feedback.text)
                    };
                    review = answer.feedback.text.length > 0 ? <Feedback feedback={feedback} key={answer.sequenceId} /> : null;
                }
                if (answer && answer.isDisqualify) {
                    AppService.isAppDisqualified = true;
                    AppService.disqType = answer.disqType;
                    const feedback = {
                        title: getTranslatedTextIfNeeded(answer.feedback.title),
                        text: getTranslatedTextIfNeeded(answer.feedback.text)
                    };
                    review = answer.feedback.text.length > 0 ? <Feedback feedback={feedback} key={answer.sequenceId} /> : null;
                }
            });
        }
        return review;
    };

    const validateConditions = (question) => {
        if (question.type === "GROUP" || question.type === "DYNAMIC_GROUP") {
            return null;
        }

        let sum = 0;
        let result = null;

        question[surveyQuestionMethods.lastAnswerListName()].forEach((id) => {
            const answer = question.answers.find((a) => a.id === id || a.text === id);
            if (answer) {
                const weight = Number.parseInt(answer.weight);
                if (!Number.isNaN(weight)) {
                    sum += weight;
                }
            }
        });

        if (question.conditions && sum > 0) {
            let isConditionApplied = false;
            question.conditions.forEach((condition) => {
                if (condition.value.length === 0 || isConditionApplied) {
                    return;
                }
                const operator = condition.value[0];
                const weight = Number.parseInt(condition.value.substring(1, condition.length));
                switch (operator) {
                    case "=":
                        if (sum === weight) {
                            isConditionApplied = true;
                            result = applyCondition(condition);
                        }
                        break;
                    case ">":
                        if (sum > weight) {
                            isConditionApplied = true;
                            result = applyCondition(condition);
                        }
                        break;
                    case "<":
                        if (sum < weight) {
                            isConditionApplied = true;
                            result = applyCondition(condition);
                        }
                        break;
                }
            });
        }
        return result;
    };

    const applyCondition = (condition) => {
        let result = null;
        const feedback = {
            title: "",
            text: condition.feedback ? getTranslatedTextIfNeeded(condition.feedback.text) : ""
        };
        if (condition.isReview) {
            if (feedback.text.length > 0) {
                result = {
                    feedback: <Feedback feedback={feedback} key={condition.sequenceId} />,
                    hidden: []
                };
            }
        }
        if (condition.isDisqualify) {
            AppService.isAppDisqualified = true;
            if (feedback.text.length > 0) {
                result = {
                    feedback: <Feedback feedback={feedback} key={condition.sequenceId} />,
                    hidden: []
                };
            }
        }
        if (condition.visibilities.length > 0) {
            if (feedback.text.length === 0) {
                result = { feedback: null, hidden: [] };
            } else {
                result = {
                    feedback: <Feedback feedback={feedback} key={condition.sequenceId} />,
                    hidden: condition.visibilities
                };
            }
        }
        return result;
    };

    if (questions?.length === 0) {
        return <SpinnerLoader destination="survey-loader" />;
    }

    return questions
        .map((question) => {
            if (AppService.hiddenQuestions[selectedTopic.id]?.includes(question.sequenceId)) {
                if (question.answers && question.answers.length > 0) {
                    const existingAnswer = question.answers[0];
                    if (existingAnswer.userAnswer && existingAnswer.userAnswer.length > 0) {
                        existingAnswer.userAnswer = "";
                    }
                }
                return;
            }

            if (isDynamicGroup && AppService?.hiddenDynamicQuestions[dynamicQuestion?.id][instanceId]?.includes(question?.sequenceId)) {
                return;
            }

            let review = null;
            let conditionResult = null;

            if (question[surveyQuestionMethods.lastAnswerListName()]) {
                review = getFeedbackOrDisqualify(question);
                conditionResult = validateConditions(question);
            }
            if (conditionResult) {
                conditionResult.hidden.forEach((h) => AppService.hiddenQuestions[selectedTopic.id].push(h));
            }

            const showWarning =
                showUnansweredWarning &&
                unansweredQuestions.containsQuestion(selectedTopic.id || selectedTopic.sequenceId, question.sequenceId);
            const isSurveyReadOnly =
                AppService.userSurveyStatus !== UserSurveyStatus.COMPLETED_INITIAL &&
                AppService.userSurveyStatus !== UserSurveyStatus.STARTED &&
                AppService.userSurveyStatus !== UserSurveyStatus.MAIN_IN_PROGRESS &&
                AppService.accountType !== "admin";
            const phoneIsInvalid = question.type === "PHONE" && !questionContext.phoneNumberIsValid;

            return (
                <div key={`container-${question.sequenceId}`}>
                    {(showWarning || phoneIsInvalid) && (
                        <UnansweredHintComponent accountType={AppService.accountType} phoneIsInvalid={phoneIsInvalid} />
                    )}
                    <Element id={question.sequenceId} name={question.sequenceId} key={question.sequenceId}>
                        <Question
                            question={question}
                            questions={questions}
                            selectedTopic={selectedTopic}
                            onAnswer={onAnswer}
                            updateProgress={updateProgress}
                            showUnansweredWarning={showUnansweredWarning}
                            isSurveyReadOnly={isSurveyReadOnly}
                            isDynamicGroup={isDynamicGroup}
                        />
                        {review}
                        {conditionResult ? conditionResult.feedback : null}
                    </Element>
                </div>
            );
        })
        .filter((q) => q !== null);
}

export default QuestionElements;
