import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";

export const NumberAnswerService = {
    isAnswerValid(answer, rangeType) {
        if (!rangeType) {
            rangeType = "range";
        }
        const numberValue = Number.parseInt(answer?.userAnswer);
        if (Number.isNaN(numberValue)) {
            return false;
        }
        if (answer[rangeType]) {
            const { upperBoundary, lowerBoundary } = answer[rangeType];
            const max = getMax(upperBoundary);
            const min = getMin(lowerBoundary);
            return numberValue >= min && numberValue <= max;
        }
        return true;
    },

    isAnswerDisq(q, answer) {
        const ranges = answer["disqualificationRange"];
        if (!ranges || ranges.length === 0 || answer?.userAnswer?.length === 0) {
            return { disqualification: false };
        }
        const numberValue = Number.parseInt(answer?.userAnswer);
        if (Number.isNaN(numberValue)) {
            return { disqualification: false };
        }
        const max = getMax(ranges.upperBoundary);
        const min = getMin(ranges.lowerBoundary);
        const disqualification = numberValue < min || numberValue > max;
        if (disqualification) {
            return {
                disqualification: true,
                key: getTranslatedTextIfNeeded(q.text, "EU"),
                value: getTranslatedTextIfNeeded(answer?.userAnswer, "EU")
            };
        }
        return { disqualification: false };
    }
};

function getMax(upperBoundary) {
    return upperBoundary ? getUpperBoundaryDependsOnInclusive(upperBoundary) : Number.MAX_SAFE_INTEGER;
}

function getMin(lowerBoundary) {
    return lowerBoundary ? getLowerBoundaryDependsOnInclusive(lowerBoundary) : Number.MIN_SAFE_INTEGER;
}

function getUpperBoundaryDependsOnInclusive(upperBoundary) {
    return upperBoundary.inclusive ? upperBoundary.value : parseInt(upperBoundary.value) - 1;
}

function getLowerBoundaryDependsOnInclusive(lowerBoundary) {
    return lowerBoundary.inclusive ? lowerBoundary.value : parseInt(lowerBoundary.value) + 1;
}
