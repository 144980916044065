import React, { useContext, useState } from "react";
import { unansweredQuestions } from "@shared/AnswersTracker/AnswersTracker";
import UnansweredNavigationComponent from "@components/surveyQuestions/unasnweredNavigation/UnansweredNavigationComponent";
import { AgeDisqualificationService } from "@service/disqualification/AgeDisqualificationService";
import { BmiDisqualificationService } from "@service/disqualification/BmiDisqualificationService";
import AppService from "@service/AppService/AppService";
import "./LiteQuestionList.scss";
import Constants from "@helper/Constants";
import { ReactComponent as Spinner } from "@resources/images/grid.svg";
import { getTranslatedTextIfNeeded } from "@shared/InternationalizationTextUtils";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import classNames from "classnames";
import LiteQuestionElements from "./LiteQuestionElements/LiteQuestionElements";
import LiteButtons from "./LiteButtons/LiteButtons";
import { UserSurveyStatus } from "../../helper/Constants";

function LiteQuestionList({
    className,
    liteSurvey,
    selectedSection,
    selectedSectionIndex,
    allSubSurveySections,
    allQuestions,
    setSelectedSection,
    setSelectedSectionIndex,
    setIsLiteSurveyFinished,
    sectionCount,
    finishButtonDisable,
    setFinishButtonDisable,
    listOfFinishedSections,
    setListOfFinishedSections,
    lang
}) {
    const [showUnansweredWarning, setShowUnansweredWarning] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [navigateStart, setNavigateStart] = useState(false);
    const [showError, setShowError] = useState(false);
    const authContext = useContext(AuthContext);
    const [emailIsValid, setEmailIsValid] = useState(true);

    const findQuestionIdByFieldName = (fieldName) => {
        let questionId = null;
        allQuestions.forEach((item) => {
            if (item.salesForceField?.fieldName === fieldName) {
                questionId = item.id;
            }
        });
        return questionId;
    };

    const calculateUserAge = () => {
        const questionId = findQuestionIdByFieldName("Birthdate");
        if (questionId) {
            const answerWithDateBirth = answers.filter((answer) => answer.questionId === questionId);
            if (answerWithDateBirth.length > 0) {
                const userBirthDate = answerWithDateBirth[0].values[0].value;
                return AgeDisqualificationService.calculateAge(userBirthDate);
            }
            return null;
        }
        return null;
    };

    const isSurveyDisqualifiedByAge = (ageDisqualificationRule) => {
        const userAge = calculateUserAge();
        if (!userAge) {
            return false;
        }
        return (
            (ageDisqualificationRule.from && userAge < ageDisqualificationRule.from) ||
            (ageDisqualificationRule.to && userAge > ageDisqualificationRule.to)
        );
    };

    const isSurveyDisqualifiedByAnswer = () => {
        let isDisqualified = false;
        answers.forEach((answer) => {
            answer.values.forEach((value) => {
                if (value.isDisqualify) {
                    isDisqualified = true;
                }
            });
        });
        return isDisqualified;
    };

    const isSurveyDisqualifiedByBmi = (bmiDisqualificationRule) => {
        return BmiDisqualificationService.isSurveyDisqualifiedByBmi(allQuestions, bmiDisqualificationRule);
    };

    const getAnswerValue = (questionId, answers) => {
        return answers.filter((answer) => answer.questionId === questionId)[0]?.values[0]?.value;
    };

    const onNextButtonClick = (selectedSection) => {
        const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(selectedSection.id);
        if (firstUnansweredQuestion) {
            setShowUnansweredWarning(true);
        } else {
            if (!listOfFinishedSections.includes(selectedSectionIndex)) {
                setListOfFinishedSections([...listOfFinishedSections, selectedSectionIndex]);
            }
            setShowUnansweredWarning(false);
            setSelectedSectionIndex(selectedSectionIndex + 1);
            setSelectedSection(allSubSurveySections[selectedSectionIndex + 1]);
        }
    };

    const onPreviousButtonClick = () => {
        setSelectedSectionIndex(selectedSectionIndex - 1);
        setSelectedSection(allSubSurveySections[selectedSectionIndex - 1]);
    };
    const isEmailUnanswered = () => {
        const emailQuestionId = selectedSection.questions.find((q) => q.salesForceField.fieldLabel === "Email")?.sequenceId;

        if (!emailQuestionId) return false;

        const unansweredInSection = unansweredQuestions.questions[selectedSection.id];
        return unansweredInSection && unansweredInSection.has(emailQuestionId);
    };

    const onlyEmailLeft = () => {
        const emailQuestionIds = selectedSection.questions.filter((q) => q.salesForceField.fieldLabel === "Email").map((q) => q.sequenceId);

        const unansweredInSection = unansweredQuestions.questions[selectedSection.id];

        if (!unansweredInSection) {
            return false;
        }

        return Array.from(unansweredInSection).every((id) => emailQuestionIds.includes(id));
    };
    const onFinishButtonClick = async () => {
        const firstUnansweredQuestion = unansweredQuestions.getFirstUnansweredQuestion(selectedSection.id);
        const previousSectionsAreAnswered = sectionCount <= listOfFinishedSections.length + 1;
        if ((firstUnansweredQuestion || !previousSectionsAreAnswered) && !onlyEmailLeft()) {
            setShowUnansweredWarning(true);
        } else {
            setFinishButtonDisable(true);
            setShowUnansweredWarning(false);
            const disqualificationRulesForAge = liteSurvey.rules.allowedAge;
            const disqualificationRulesForBmi = liteSurvey.rules.allowedBmi;
            const userDisqualified =
                isSurveyDisqualifiedByAge(disqualificationRulesForAge) ||
                isSurveyDisqualifiedByAnswer() ||
                isSurveyDisqualifiedByBmi(disqualificationRulesForBmi);

            const emailQuestionId = findQuestionIdByFieldName("Email");
            const firstNameQuestionId = findQuestionIdByFieldName("FirstName");
            const lastNameQuestionId = findQuestionIdByFieldName("LastName");

            let email = null;
            let phoneValue = "";
            let firstName = `First name${(+new Date()).toString(10)}`;
            let lastName = `Last name${(+new Date()).toString(10)}`;

            if (emailQuestionId) {
                email = getAnswerValue(emailQuestionId, answers);
            }

            if (firstNameQuestionId) {
                firstName = getAnswerValue(firstNameQuestionId, answers);
            }

            if (lastNameQuestionId) {
                lastName = getAnswerValue(lastNameQuestionId, answers);
            }

            const lowerRandomLetter = generateRandomLetter();
            const upperRandomLetter = generateRandomLetter().toUpperCase();
            const user = {
                firstName,
                lastName,
                email,
                password: `${lowerRandomLetter}${(+new Date()).toString(16)}${upperRandomLetter}`,
                address: "",
                primaryPhoneNumber: phoneValue,
                role: "SURROGATE"
            };

            if (email === localStorage.getItem("current-email") || isEmailUnanswered()) {
                setFinishButtonDisable(false);
                setShowUnansweredWarning(true);
                return;
            }

            setEmailIsValid(true);
            authContext.cleanToken();

            try {
                const res = await authContext.signup(user);
                try {
                    const response = await AppService.getAxios().get("/api/user/survey");
                    const userStatus = userDisqualified ? UserSurveyStatus.REJECTED : UserSurveyStatus.STARTED;

                    try {
                        const res = await AppService.getAxios().put("/api/user/survey/status", {
                            userSurveyId: response.data.userSurveyId,
                            status: userStatus,
                            surveyScore: 0
                        });

                        const answerList = answers.map((answer) => {
                            delete answer.values.isDisqualify;
                            return {
                                surveyId: response.data.id,
                                questionId: answer.questionId,
                                values: answer.values
                            };
                        });

                        try {
                            await AppService.getAxios().post("/api/user/answer/list", { userAnswerList: answerList });
                            let currentLang = lang ? lang : "en";

                            if (userStatus === UserSurveyStatus.STARTED) {
                                if (window.location.pathname === "/app/surrogates/register") {
                                    setNavigateStart(true);
                                } else {
                                    window.open(
                                        `${Constants.SURVEY_URL}/app/transfer?from=LITE&lang=${currentLang}&j=${authContext.getToken()}`,
                                        "_top"
                                    );
                                }
                            } else {
                                setIsLiteSurveyFinished(true);
                            }
                        } catch {
                            setFinishButtonDisable(false);
                        }
                    } catch {
                        setFinishButtonDisable(false);
                    }

                    unansweredQuestions.clear();
                } catch {
                    setShowError(true);
                    setFinishButtonDisable(false);
                }
            } catch (err) {
                console.log(err);
                if (err.response.data.code && err.response.data.code === 400) {
                    const message = err.response.data.message;
                    if (message === "Current email is not valid") {
                        setEmailIsValid(false);
                        setFinishButtonDisable(false);
                        localStorage.setItem("current-email", email);
                        setShowUnansweredWarning(true);
                    }
                }
                setFinishButtonDisable(false);
            }
        }
    };

    const generateRandomLetter = () => {
        const alphabet = "abcdefghijklmnopqrstuvwxyz";
        const randomIndex = new Uint32Array(1);
        window.crypto.getRandomValues(randomIndex);
        return alphabet.charAt(randomIndex[0] % alphabet.length);
    };

    if (navigateStart) {
        return <Redirect to={`/survey/`} push={true} />;
    }

    const renderValidationErrors = () => {
        if (!emailIsValid) {
            return (
                <div className="phone-error">
                    We apologize. You are not able to proceed because it looks like you have inputted an invalid email
                </div>
            );
        }
        if (onlyEmailLeft() && isEmailUnanswered()) {
            return <div className="email-error">Please enter a valid email address</div>;
        }
    };

    return (
        <div className={classNames("question-list", className)}>
            {liteSurvey?.sections?.length ? (
                <div className="question-list-container">
                    <div className="section-title">{getTranslatedTextIfNeeded(selectedSection.title)}</div>
                    <div className="questions-wrapper">
                        {selectedSection.questions.map((question) => (
                            <LiteQuestionElements
                                key={question.sequenceId}
                                question={question}
                                selectedSection={selectedSection}
                                setSelectedSection={setSelectedSection}
                                answers={answers}
                                setAnswers={setAnswers}
                                showUnansweredWarning={showUnansweredWarning}
                                emailIsValid={emailIsValid}
                            />
                        ))}
                        {showUnansweredWarning && renderValidationErrors()}
                    </div>
                </div>
            ) : (
                "Not Found"
            )}
            {allSubSurveySections.length > 1 && (
                <ul className="active-section-container">
                    {allSubSurveySections.map((item, index) => (
                        <li key={`${index}-item`} className={`section ${index === selectedSectionIndex ? "active" : ""}`} />
                    ))}
                </ul>
            )}
            {showError && (
                <div className="register-error">
                    <div>{AppService.getValidationErrorText()}</div>
                </div>
            )}
            <div className="button-section">
                <div className="left-part">
                    {selectedSectionIndex !== 0 ? (
                        <span
                            className="previous"
                            onClick={() => {
                                onPreviousButtonClick();
                            }}
                        >
                            Previous
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <div className="center-part">
                    {showUnansweredWarning && !onlyEmailLeft() && unansweredQuestions.getFirstUnansweredQuestion(selectedSection.id) ? (
                        <UnansweredNavigationComponent sectionId={selectedSection.id} accountType="surrogate" />
                    ) : (
                        ""
                    )}
                    {finishButtonDisable ? <Spinner className="spinner" /> : ""}
                </div>
                <LiteButtons
                    allSubSurveySections={allSubSurveySections}
                    selectedSectionIndex={selectedSectionIndex}
                    selectedSection={selectedSection}
                    finishButtonDisable={finishButtonDisable}
                    onFinishButtonClick={onFinishButtonClick}
                    onNextButtonClick={onNextButtonClick}
                />
            </div>
        </div>
    );
}

export default LiteQuestionList;
