import React, { useEffect, useState } from "react";
import { getAdminLogs } from "../../../../requests/AdminAPIRequests/adminAPIRequests";
import { useParams } from "react-router-dom";
import "./AdminLogs.scss";
import AppService from "../../../../service/AppService/AppService";
import ModalPopupComponent from "../../../../components/modalPopup/ModalPopupComponent";
import PreviousCyclesModal from "./PreviousCyclesModal/PreviousCyclesModal";
import DynamicGroupModalContent from "./DynamicGroupModalContent/DynamicGroupModalContent";
import PhotosModalContent from "./PhotosModalContent/PhotosModalContent";
import { formatAddress, formatDate, formatDateFromString, formatPhoneNumber } from "../../../../shared/FormattingUtils/FormattingUtils";

function AdminLogs() {
    const { integrationId } = useParams();
    const id = AppService.edId;

    const [isLoaded, setIsLoaded] = useState(false);
    const [logsList, setLogsList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeModalLog, setActiveModalLog] = useState(null);

    useEffect(() => {
        (async () => await getLogs())();
    }, []);

    const getLogs = async () => {
        setIsLoaded(false);
        const resp = await getAdminLogs(id);
        if (resp.data) {
            const filteredList = filterUnchangedLogs(mergeConsecutiveLogs(resp.data));
            setLogsList(filteredList);
            setIsLoaded(true);
        }
    };

    const filterUnchangedLogs = (logsList) => {
        return logsList.filter((log) => {
            if (log.target === "Previous Cycles") {
                const oldCycles = JSON.parse(log.oldValue || "[]");
                const newCycles = JSON.parse(log.newValue || "[]");
                return compareCycles(oldCycles, newCycles).length > 0;
            }
            return log.oldValue !== log.newValue;
        });
    };
    const mergeConsecutiveLogs = (logs) => {
        if (!logs.length) return [];
        logs.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

        const mergedLogs = [];
        const ONE_MINUTES = 60 * 1000;

        for (let i = 0; i < logs.length; i++) {
            const curr = logs[i];
            let mergedLog = { ...curr };

            let j = i + 1;
            while (j < logs.length && logs[j].target === curr.target) {
                const currTime = logs[j - 1].time && new Date(Date.UTC(...logs[j - 1].time)).getTime();
                const nextTime = logs[j].time && new Date(Date.UTC(...logs[j].time)).getTime();
                if (currTime - nextTime > ONE_MINUTES) {
                    break;
                }

                mergedLog.oldValue = logs[j].oldValue;
                j++;
            }

            mergedLogs.push(mergedLog);

            i = j - 1;
        }

        return mergedLogs;
    };

    const buildValue = (log, key) => {
        const currentLog = log[key];
        if (!currentLog) {
            return "-";
        }

        if (currentLog === "_REMOVED_") {
            return "REMOVED";
        }

        if (log.target === "Previous Cycles" && key === "newValue") {
            return (
                <div className="diff-btn" onClick={() => openModal(log, "cycle")}>
                    Click to see difference
                </div>
            );
        }

        switch (log.questionType) {
            case "DATE":
                return formatDateFromString(currentLog);
            case "PHONE":
                return formatPhoneNumber(currentLog);
            case "ADDRESS":
                return formatAddress(currentLog);
            case "DYNAMIC_GROUP":
                if (key === "newValue") {
                    return (
                        <div className="diff-btn" onClick={() => openModal(log, "dynamic")}>
                            Click to see difference
                        </div>
                    );
                }
                return "-";
            case "UPLOAD":
            case "MULTI_UPLOAD":
                if (key === "newValue") {
                    return (
                        <div className="diff-btn" onClick={() => openModal(log, log.questionType === "UPLOAD" ? "single" : "gallery")}>
                            Click to see new uploads
                        </div>
                    );
                }
                return "-";
            default:
                return currentLog;
        }
    };

    const openModal = (log, type) => {
        setActiveModalLog({ ...log, type });
        setIsModalOpen(true);
    };

    const compareCycles = (oldCycles, newCycles) => {
        let changes = [];
        for (let i = 0; i < newCycles.length; i++) {
            let cycleChanges = [];
            const oldCycle = oldCycles[i] || {};
            const newCycle = newCycles[i];
            Object.keys(newCycle).forEach((key) => {
                const oldValue = oldCycle[key];
                const newValue = newCycle[key];
                if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                    if (["preg_outcome", "cryo_outcome"].includes(key)) {
                        cycleChanges.push({
                            key,
                            oldValue: oldValue ? JSON.stringify(oldValue.text, null, 2) : "N/A",
                            newValue: JSON.stringify(newValue.text, null, 2)
                        });
                        return;
                    }
                    cycleChanges.push({
                        key,
                        oldValue: oldValue ? JSON.stringify(oldValue, null, 2) : "N/A",
                        newValue: JSON.stringify(newValue, null, 2)
                    });
                }
            });
            if (cycleChanges.length > 0) {
                changes.push({ cycle: i + 1, changes: cycleChanges });
            }
        }
        return changes;
    };
    const renderModalView = () => {
        switch (activeModalLog.type) {
            case "cycle":
                return (
                    <PreviousCyclesModal
                        oldValue={activeModalLog.oldValue}
                        newValue={activeModalLog.newValue}
                        compareCycles={compareCycles}
                    />
                );
            case "dynamic":
                return <DynamicGroupModalContent log={activeModalLog} />;
            case "single":
            case "gallery":
                return <PhotosModalContent log={activeModalLog} />;
        }
    };

    return (
        <div className="logs-container">
            <h1 className="title">
                Changelog for <span className="id">{integrationId}</span> user
            </h1>
            <table className="logs-list">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Question</th>
                        <th>Old value</th>
                        <th>New value</th>
                        <th>Change on</th>
                        <th>Change by</th>
                    </tr>
                </thead>
                <tbody>
                    {logsList.map((log, index) => (
                        <tr key={`${log.target}-${index}`}>
                            <td>{index + 1}</td>
                            <td>{log.target}</td>
                            <td>{buildValue(log, "oldValue")}</td>
                            <td>{buildValue(log, "newValue")}</td>
                            <td>{log.time ? formatDate(log.time, false) : ""}</td>
                            <td>{log.admin}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {logsList.length === 0 && isLoaded && <div>No changes yet</div>}
            {isModalOpen && <ModalPopupComponent onDialogClose={() => setIsModalOpen(false)}>{renderModalView()}</ModalPopupComponent>}
        </div>
    );
}

export default AdminLogs;
