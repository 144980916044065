import React, { useState, useEffect } from "react";
import "./CalendarComponent.scss";
import DropDown from "@components/dropDowns/dropDown/DropDownComponent";
import i18n from "i18next";

function CalendarComponent({ answer, onChange }) {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const monthItems = monthNames.map((name) => {
        return { text: name };
    });
    const yearItems = Array.from({ length: 2025 - 1920 + 1 }, (_, i) => {
        return { text: `${2025 - i}` };
    });

    const answerDate = answer.userAnswer?.split("T")[0]?.split("-");
    const [selectedYear, setSelectedYear] = useState(answer.userAnswer ? parseInt(answerDate[0]) : undefined);
    const [selectedMonth, setSelectedMonth] = useState(answer.userAnswer ? parseInt(answerDate[1]) - 1 : undefined);
    const [selectedDay, setSelectedDay] = useState(answer.userAnswer ? parseInt(answerDate[2]) : undefined);

    const getSelectedYear = () => (selectedYear ? { text: selectedYear + "" } : { text: "" });
    const getSelectedMonth = () => (selectedMonth !== undefined ? { text: i18n.t(monthNames[selectedMonth]) } : { text: "" });

    const getDayItems = () => {
        let daysInMonth = 31;
        if (selectedYear && selectedMonth !== undefined) {
            daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        }
        return Array(daysInMonth)
            .fill()
            .map((_, i) => {
                return { text: i + 1 + "" };
            });
    };

    const getSelectedDay = () => (selectedDay ? { text: selectedDay + "" } : { text: "" });

    const monthChange = (selectedMonth) => {
        const selectedMonthIndex = monthNames.findIndex((month) => month === selectedMonth.text);
        setSelectedMonth(selectedMonthIndex);
        updateUserAnswer(selectedYear, selectedMonthIndex, selectedDay);
    };

    const dayChange = (selectedDay) => {
        setSelectedDay(selectedDay.text);
        updateUserAnswer(selectedYear, selectedMonth, selectedDay.text);
    };

    const yearChange = (selectedYear) => {
        setSelectedYear(selectedYear.text);
        updateUserAnswer(selectedYear.text, selectedMonth, selectedDay);
    };

    const updateUserAnswer = (selectedYear, selectedMonth, selectedDay) => {
        if (selectedMonth !== undefined && selectedDay && selectedYear) {
            const userDate = new Date(selectedYear, selectedMonth, selectedDay);
            const buildDateString = new Date(Date.UTC(userDate.getFullYear(), userDate.getMonth(), userDate.getDate())).toISOString();
            if (userDate.getDate().toString() === selectedDay.toString()) {
                onChange(buildDateString);
            } else {
                setSelectedDay("");
            }
        }
    };

    useEffect(() => {
        setSelectedYear(answer.userAnswer ? parseInt(answerDate[0]) : undefined);
        setSelectedMonth(answer.userAnswer ? parseInt(answerDate[1]) - 1 : undefined);
        setSelectedDay(answer.userAnswer ? parseInt(answerDate[2]) : undefined);
    }, [answer.value]);

    return (
        <div className="date-picker">
            <div className="month-container">
                <DropDown
                    items={monthItems}
                    selectedItem={getSelectedMonth()}
                    placeholder={i18n.t("month")}
                    onChange={monthChange}
                    datepicker={true}
                />
            </div>
            <div className="day-container">
                <DropDown
                    items={getDayItems()}
                    selectedItem={getSelectedDay()}
                    placeholder={i18n.t("day")}
                    onChange={dayChange}
                    datepicker={true}
                />
            </div>
            <div className="year-container">
                <DropDown
                    items={yearItems}
                    selectedItem={getSelectedYear()}
                    placeholder={i18n.t("year")}
                    onChange={yearChange}
                    datepicker={true}
                />
            </div>
        </div>
    );
}

export default CalendarComponent;
